/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";

import Notiflix from "notiflix";
import PostApiCall from "../../Api/postApi";
// import Footer from "../../Footer";
// import Menu from "../../Header";
import { useParams } from "react-router-dom";
// import PaymentProcess from "./assets/images/paymentprocessing.png"
import PaymentProcessimage from "../../assets/images/paymentprocessing.png";

const PaymentProcess = () => {
    let params = useParams();
    const [prodData, setProdData] = useState([])
    const [orderData, setOrderData] = useState([])
    const [mainOrders, setMainOrders] = useState([])

    useEffect(() => {
        Notiflix.Loading.init({
            svgColor: "#ee1b2f",
        });

        getOrderData()
    }, [])

    const getOrderData = () => {

        PostApiCall.postRequest(
            {
                whereClause: `where fld_orderNumber='MELINV7-2023/100007'`

            },
            "GetWebsiteOrderDetails"
        ).then((results1) =>
            // const objs = JSON.parse(result._bodyText)
            results1.json().then((obj) => {
                if (results1.status == 200 || results1.status == 201) {
                    //
                    setOrderData(obj.data[0]);
                    setProdData(obj.data);
                    setMainOrders(obj.data[0])
                    if (obj.data[0].fld_payment_status == null) {
                        // this.sendSampleKitMailer()
                        window.location.href = `/paymentsuccess/${params.txnid}/${params.mipid}`;
                    } else {
                        window.location.href = `/paymentsuccess/MELINV7-2023/100007`;
                    }
                }
            }))
    }



    return (
        <>
            {/* <Menu /> */}
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-center text-align-center text-success" style={{ marginTop: "82px" }}>
                            <div class="spinner-border" role="status">

                            </div>
                            <p className="ml-3 mt-1" style={{ color: "#212529" }}>Order Processing...</p>
                        </div>

                        <div class="payment-box" >
                            <img src={PaymentProcessimage} class="paymentFail img-fluid" alt="Payment Process" height="550px" width="550px"></img>

                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    );
}

export default PaymentProcess