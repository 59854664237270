import fmt from "indian-number-format";
import React, { useEffect, useState } from 'react';
import {
    Col, Container, Row, Table
} from "react-bootstrap";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import PostApiCall from "../../Api/postApi";
import OrderSummary1 from "../../components/OrderSummary/OrderSummary1";
import ManageCustomerAPI from "../../pages/Checkout/ManageCustomerAPI";
import "./checkout.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const CheckoutOrderSummary = () => {
    const [shippingAddress, setShippingAddress] = useState("");
    const [billingAddress, setBillingAddress] = useState("");
    const [cartData, setCartData] = useState([]);
    const [cartId, setCartId] = useState("");
    const [mainOrder, setMainOrder] = useState([]);
    const [mainOrders, setMainOrders] = useState([]);
    const [txnId, setTxnId] = useState([]);
    const [customerHash, setCustomerHash] = useState("");
    // const [amountInWords, setAmountInWords] = useState(null);
    const [clientPhoneNumber, setClientPhoneNumber] = useState(null);
    const [clientEmail, setClientEmail] = useState(null);
    const [clientLogo, setClientLogo] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientAddress, setClientAddress] = useState(null);
    const [clientGST, setClientGST] = useState(null);
    const [clientWesite, setClientWesite] = useState(null);
    const [storeOpen, setStoreOpen] = useState("");
    const [couponId, setCouponId] = useState("");
    const [couponDataId, setCouponDataId] = useState("");
    const [couponCode, setCouponCode] = useState("");
    const [subtotal, setSubtotal] = useState(0);
    const [offerDiscountAmount, setOfferDiscountAmount] = useState(0);
    const [couponDiscountAmount, setCouponDiscountAmount] = useState(0);
    const [shippingCharges, setShippingCharges] = useState(0);
    const [gst, setGst] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [gstonShipping, setGstonShipping] = useState(null);
    const [customerLastName, setCustomerLastName] = useState(null);
    const [customerEmail, setCustomerEmail] = useState(null);
    const [customerPhone, setCustomerPhone] = useState(null);
    const [customerID, setCustomerID] = useState("");

    useEffect(() => {
        // GetApiCall.getRequest("getstorestatus").then((resultdes) =>
        //     resultdes.json().then((obj) => {
        // setStoreOpen(obj.data[0].fld_store_status)
        //     })
        // );
        ManageCustomerAPI.postRequest(
            {
                whereClause: `where fld_company_id = ${12}`,
            },
            "API/GetClientData"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    setClientAddress(obj.data[0].fld_address)
                    setClientEmail(obj.data[0].fld_email)
                    setClientGST(obj.data[0].fld_gst)
                    setClientWesite(obj.data[0].fld_website)
                    setClientPhoneNumber(obj.data[0].fld_mobilenumber)
                    setClientName(obj.data[0].fld_companyname)
                    setClientLogo(obj.data[0].fld_storelogo)
                }
            })
        );
        var OrderDetails = JSON.parse(localStorage.getItem("OrderDetails"));
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        PostApiCall.postRequest(
            {
                orderid:
                    localStorage.getItem("OrderDetails") == null
                        ? null
                        : OrderDetails[0].order_id,
                id:
                    localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_customerid,
            },
            "GetCartWebsite"
        ).then((result) => {
            result.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    setCartData(obj.data);
                    setCartId(obj.data[0].fld_cart_id + "")
                    setCouponId(obj.data[0].fld_offerid);
                    setCouponDataId(obj.data[0].fld_coupon_id);
                    setCouponCode(obj.data[0].fld_code);
                    setSubtotal(obj.data[0].fld_total_net_amount)
                    setOfferDiscountAmount(obj.data[0].fld_cart_discount_amount);
                    setCouponDiscountAmount(obj.data[0].fld_coupon_discount == null ||
                        obj.data[0].fld_coupon_discount == 0
                        ? 0.0
                        : obj.data[0].fld_coupon_discount);
                    setShippingCharges(obj.data[0].fld_shipping_charge == null
                        ? 0
                        : obj.data[0].fld_shipping_charge);
                    setGst(obj.data[0].fld_total_gst);
                    setGrandTotal(obj.data[0].fld_cart_amount_no_shipping);
                    setGstonShipping(obj.data[0].fld_shipping_charge_gst == null
                        ? 0.00
                        : obj.data[0].fld_shipping_charge_gst);
                    setMainOrder(obj.data);
                    setMainOrders(obj.data[0])
                    setCustomerHash(obj.hash);
                    setTxnId(obj.data[0].fld_payment_trx_num);
                    setCustomerLastName(obj.data[0].customername);
                    setCustomerEmail(obj.data[0].fld_email)
                    setCustomerPhone(obj.data[0].fld_mobile)
                    setCustomerID(obj.data[0].fld_customer_id + "")
                    setBillingAddress(obj.data[0].fld_billing_address + "");
                    setShippingAddress(obj.data[0].shipping_address + "");
                }
            });
        });
    }, [])





    return (
        <>
            <Container className="mt-4">
                <Row style={{ marginTop: "80px" }}>
                    <Col md={6} sm={12}>
                        <Row>
                            <Col sm={12}>
                                <div className="section">
                                    {mainOrder.map((data, index) => {
                                        if (index == 0) {
                                            return (
                                                <div className="content-box content-box-tab">
                                                    <div
                                                        role="table"
                                                        className="content-box__row content-box__row--tight-spacing-vertical"
                                                    >
                                                        <div
                                                            role="row"
                                                            className="review-block"
                                                        >
                                                            <div className="review-block__inner">
                                                                <div
                                                                    role="rowheader"
                                                                    className="review-block__label"
                                                                >
                                                                    Contact
                                                                </div>
                                                                <div
                                                                    role="cell"
                                                                    className="review-block__content"
                                                                >
                                                                    <bdo dir="ltr">
                                                                        +91{" "}
                                                                        {
                                                                            data.fld_mobile
                                                                        }
                                                                    </bdo>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            role="row"
                                                            className="review-block"
                                                        >
                                                            <div className="review-block__inner">
                                                                <div
                                                                    role="rowheader"
                                                                    className="review-block__label"
                                                                >
                                                                    Ship to
                                                                </div>
                                                                <div
                                                                    role="cell"
                                                                    className="review-block__content"
                                                                >
                                                                    <address className="address address--tight">
                                                                        {
                                                                            data.address_name
                                                                        }{" "}
                                                                        {
                                                                            data.fld_address_1
                                                                        }{" "}
                                                                        {data.CityName}-{" "}
                                                                        {data.fld_pincode}
                                                                        , {data.StateName}
                                                                        ,{" "}
                                                                        {data.CountryName}
                                                                        ,{" "}
                                                                        {
                                                                            data.fld_landmark
                                                                        }
                                                                        <address></address>
                                                                    </address>
                                                                </div>
                                                            </div>
                                                            <div
                                                                role="cell"
                                                                className="review-block__link"
                                                            >
                                                                {/* <Link
                                                                    className="link--small"
                                                                    to="/checkout"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        Change
                                                                    </span>
                                                                </Link> */}
                                                            </div>
                                                        </div>
                                                        <div
                                                            role="row"
                                                            className="review-block"
                                                        >
                                                            <div className="review-block__inner">
                                                                <div
                                                                    role="rowheader"
                                                                    className="review-block__label"
                                                                >
                                                                    Payment Method
                                                                </div>
                                                                <div
                                                                    role="cell"
                                                                    className="review-block__content"
                                                                >
                                                                    <address className="address address--tight">
                                                                        {/* {data.Payment_Mode == "COD"
                                                                                ? "Cash on Delivery"
                                                                                : data.Payment_Mode} */}
                                                                        Online Payment
                                                                        <address></address>
                                                                    </address>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </Col>
                        </Row>


                    </Col>
                    <Col md={6} sm={12}>
                        <h5>Order Summary</h5>
                        <OrderSummary1 />
                        <Row className="mt-4">
                            <Table responsive borderless>
                                <tbody borderless>
                                    <tr className="total-line total-line--subtotal">
                                        <th className="total-line__name" scope="row">
                                            Total MRP
                                        </th>
                                        <td className="total-line__price">
                                            <span
                                                className="order-summary__emphasis skeleton-while-loading"
                                                data-checkout-subtotal-price-target="68000"
                                            >
                                                ₹ {parseFloat(subtotal).toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="total-line total-line--shipping">
                                        <th className="total-line__name" scope="row">
                                            <span>Discount on MRP</span>
                                        </th>
                                        <td className="total-line__price">
                                            <span
                                                className="skeleton-while-loading order-summary__emphasis"
                                                data-checkout-total-shipping-target="1961"
                                            >
                                                ₹ {parseFloat(offerDiscountAmount).toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="total-line total-line--shipping">
                                        <th className="total-line__name" scope="row">
                                            <span>Coupon Discount</span>
                                        </th>
                                        <td className="total-line__price">
                                            <span
                                                className="skeleton-while-loading order-summary__emphasis"
                                                data-checkout-total-shipping-target="1961"
                                            >
                                                ₹ {parseFloat(couponDiscountAmount).toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="total-line total-line--shipping">
                                        <th className="total-line__name" scope="row">
                                            <span>GST</span>
                                        </th>
                                        <td className="total-line__price">
                                            <span
                                                className="skeleton-while-loading order-summary__emphasis"
                                                data-checkout-total-shipping-target="1961"
                                            >
                                                ₹ {parseFloat(gst).toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="total-line total-line--shipping">
                                        <th className="total-line__name" scope="row">
                                            <span>Convenience Fee</span>
                                        </th>
                                        <td className="total-line__price">
                                            <span
                                                className="skeleton-while-loading order-summary__emphasis"
                                                data-checkout-total-shipping-target="1961"
                                            >
                                                ₹ {parseFloat(shippingCharges).toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="total-line total-line--shipping">
                                        <th className="total-line__name" scope="row">
                                            <span>GST on Shipping</span>
                                        </th>
                                        <td className="total-line__price">
                                            <span
                                                className="skeleton-while-loading order-summary__emphasis"
                                                data-checkout-total-shipping-target="1961"
                                            >
                                                ₹ {parseFloat(gstonShipping).toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot className="total-line-table__footer">
                                    <tr className="total-line">
                                        <th
                                            className="total-line__name payment-due-label"
                                            scope="row"
                                            style={{ paddingTop: "1.5rem !important" }}
                                        >
                                            <span className="payment-due-label__total">
                                                Total
                                            </span>

                                        </th>
                                        <td
                                            className="total-line__price payment-due"
                                            data-presentment-currency="USD"
                                        >
                                            <span className="payment-due__price skeleton-while-loading--lgskeleton-while-loading order-summary__emphasis">
                                                ₹ {parseFloat(grandTotal).toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Row>
                        <Row className="mb-5">
                            <Col>
                                {
                                    storeOpen == "Close" ?
                                        <div className="store-close">
                                            <p className="m-0">We are closed at the moment</p>
                                        </div>
                                        :
                                        <div >
                                            {mainOrder.Payment_Mode != "COD" ? (
                                                <form
                                                    action="https://secure.payu.in/_payment"
                                                    id="payment_form"
                                                    method="Post"
                                                >
                                                    <div style={{ display: "none" }}>
                                                        <input type="hidden" id="udf1" name="udf1" value={customerID} />
                                                        <input type="hidden" id="udf2" name="udf2" value={cartId} />
                                                        <input type="hidden" id="udf3" name="udf3" value={billingAddress} />
                                                        <input type="hidden" id="udf4" name="udf4" value={shippingAddress} />
                                                        <input
                                                            type="hidden"
                                                            id="surl"
                                                            name="surl"
                                                            value="https://api.bechofy.in/melhua-api/Response" />
                                                        <input
                                                            type="hidden"
                                                            id="furl"
                                                            name="furl"
                                                            value="https://api.bechofy.in/melhua-api/Response" />
                                                        <input
                                                            type="hidden"
                                                            id="curl"
                                                            name="curl"
                                                            value="https://api.bechofy.in/melhua-api/Response" />
                                                        <input
                                                            type="hidden"
                                                            id="key"
                                                            name="key"
                                                            value="MFztFQKY" />
                                                        <div class="dv">
                                                            <span class="text">
                                                                <label>Transaction/Order ID:</label>
                                                            </span>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="txnid"
                                                                    name="txnid"
                                                                    placeholder="Transaction ID"
                                                                    value={txnId} />
                                                            </span>
                                                        </div>

                                                        <div class="dv">
                                                            <span class="text">
                                                                <label>Amount:</label>
                                                            </span>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    value={parseFloat(grandTotal).toFixed(2)} />
                                                            </span>
                                                        </div>

                                                        <div class="dv">
                                                            <span class="text">
                                                                <label>Product Info:</label>
                                                            </span>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="productinfo"
                                                                    name="productinfo"
                                                                    placeholder="Product Info"
                                                                    value={"Products"} />
                                                            </span>
                                                        </div>

                                                        <div class="dv">
                                                            <span class="text">
                                                                <label>First Name:</label>
                                                            </span>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="firstname"
                                                                    name="firstname"
                                                                    placeholder="First Name"
                                                                    value={customerLastName} />
                                                            </span>
                                                        </div>

                                                        <div class="dv">
                                                            <span class="text">
                                                                <label>Last Name:</label>
                                                            </span>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="Lastname"
                                                                    name="Lastname"
                                                                    placeholder="Last Name"
                                                                    value={customerLastName} />
                                                            </span>
                                                        </div>

                                                        <div class="dv">
                                                            <span class="text">
                                                                <label>Email ID:</label>
                                                            </span>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="email"
                                                                    name="email"
                                                                    placeholder="Email ID"
                                                                    value={customerEmail} />
                                                            </span>
                                                        </div>

                                                        <div class="dv">
                                                            <span class="text">
                                                                <label>Mobile/Cell Number:</label>
                                                            </span>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="phone"
                                                                    name="phone"
                                                                    placeholder="Mobile/Cell Number"
                                                                    value={customerPhone} />
                                                            </span>
                                                        </div>

                                                        <div class="dv">
                                                            <span class="text">
                                                                <label>Hash:</label>
                                                            </span>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="hash"
                                                                    name="hash"
                                                                    placeholder="Hash"
                                                                    value={customerHash} />
                                                            </span>
                                                        </div>
                                                        <div class="dv">
                                                            <span class="text">
                                                                <label>Service Provider:</label>
                                                            </span>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="service_provider"
                                                                    name="service_provider"
                                                                    placeholder="service_provider"
                                                                    value={"payu_paisa"} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <button
                                                            // style={{
                                                            //     marginLeft: "5px",
                                                            //     marginBottom: "15px",
                                                            //     background: "#59c5d1",
                                                            //     borderRadius: "3px",
                                                            //     color: "white",
                                                            //     width: "auto",
                                                            // }}
                                                            className="btn btn-theme w-100"
                                                        >
                                                            Pay & Place Order <br />
                                                            ₹ {
                                                                fmt.formatFixed(parseFloat(grandTotal), 2)}
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        localStorage.removeItem("OrderDetails");
                                                        window.location.href = `/paymentprocess/${mainOrders.fld_payment_trx_num}`;
                                                    }}
                                                    className="butn"
                                                >
                                                    Place Order
                                                </button>
                                            )}
                                        </div>
                                }
                            </Col>
                            <Col className="text-end returntopage" onClick={() => {
                                window.location.href = "/checkout"
                            }}>
                                <MdOutlineKeyboardArrowLeft className="mx-2" /> Return to checkout
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default CheckoutOrderSummary;
