import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import PostApiCall from "../../Api/postApi";
import { store } from "../../context/store";
import "./AddRemoveItems1.css";
function AddRemoveItems1(props) {
  const {
    ProductCart,
    setBestSeller,
    setFeaturedProducts,
    setNewArrivals,
    setCart,
    setLoginPopupWebsite,
    setProductCart,
  } = useContext(store);
  const VariantId = props.VariantId;
  const CartId = props.CartId;
  const CartDetailsId = props.CartDetailsId;
  const Quantity = props.Quantity;
  const MaxQuantity = props.MaxQuantity;
  const MinQuantity = props.MinQuantity;
  const [decButton, setDecButton] = useState(false);
  const GetCartdetails = () => {
    Notiflix.Loading.dots("");
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    var orderid = 0;
    if (localStorage.getItem("OrderDetails") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    if (localStorage.getItem("CustomerData") === null) {
      setLoginPopupWebsite(true);
    } else {
      var login = localStorage.getItem("CustomerData");
      var logindetails = JSON.parse(login);
      if (localStorage.getItem("OrderDetails") !== null) {
        var Order = localStorage.getItem("OrderDetails");
        var Orderdetails = JSON.parse(Order);
        PostApiCall.postRequest(
          {
            orderid:
              localStorage.getItem("OrderDetails") == null
                ? null
                : Orderdetails[0].order_id,
            id:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
          },
          "GetCartWebsite"
        )
          .then((res) => res.json())
          .then((res1) => {
            if (res1.data) {
              setProductCart(res1.data);
              setCart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
              Notiflix.Loading.remove();
            }
          });
      }
    }
    PostApiCall.postRequest(
      {
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        categoryid: null,
        productid: null,
        variantid: null,
        sectionname: "Featured Products",
        orderid: orderid,
        itemchoice: "Random",
      },
      "GetWebsiteHomePageItems"
    ).then((results) => {
      results.json()?.then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // ;
          setFeaturedProducts(obj.data);
        }
      });
    });
    PostApiCall.postRequest(
      {
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        categoryid: null,
        productid: null,
        variantid: null,
        sectionname: "New Arrivals",
        orderid: orderid,
        itemchoice: "Random",
      },
      "GetWebsiteHomePageItems"
    ).then((results) => {
      results.json()?.then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // ;
          setNewArrivals(obj.data);
        }
      });
    });
    PostApiCall.postRequest(
      {
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        categoryid: null,
        productid: null,
        variantid: null,
        sectionname: "Best Seller",
        orderid: orderid,
        itemchoice: "Random",
      },
      "GetWebsiteHomePageItems"
    ).then((results) => {
      results.json()?.then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // ;
          setBestSeller(obj.data);
        }
      });
    });
  };
  return (
    <div className="_quantity-wrapper">
      <label className="visually-hidden" for="Quantity-1">
        Quantity
      </label>
      <button
        class="quantity__button no-js-hidden listing-page test"
        name="minus"
        type="button"
        disabled={decButton == true ? true : false}
        onClick={() => {
          setDecButton(true);
          var login = localStorage.getItem("CustomerData");
          var logindetails = JSON.parse(login);
          var Order = localStorage.getItem("OrderDetails");
          var Orderdetails = JSON.parse(Order);
          if (MinQuantity != Quantity && Quantity > 1) {
            Notiflix.Loading.dots();
            PostApiCall.postRequest(
              {
                orderdate: moment().format("lll"),
                itemdetails: `[{"Item":${VariantId},"Quantity":${-1}}]`,
                status: "INCART",
                customerid:
                  localStorage.getItem("CustomerData") == null
                    ? null
                    : logindetails[0].fld_customerid,
                createdon: moment().format("lll"),
                updatedon: moment().format("lll"),
                orderid:
                  localStorage.getItem("OrderDetails") == null
                    ? 0
                    : Orderdetails[0].order_id,
                updatedby:
                  localStorage.getItem("CustomerData") == null
                    ? null
                    : logindetails[0].fld_userid,
              },
              "AddToCartWebsite"
            ).then((result) =>
              result.json().then((obj3) => {
                // ;
                if (result.status == 200 || result.status == 201) {
                  GetCartdetails();

                  // setdecButton(true);
                  Notiflix.Notify.success("Product quantity updated.");
                  Notiflix.Loading.remove();
                  setDecButton(false);
                  // setdecButton(false);
                  GetCartdetails();
                  if (localStorage.getItem("OrderDetails") == null) {
                    //
                    localStorage.setItem(
                      "OrderDetails",
                      JSON.stringify(obj3.data)
                    );
                  }
                  GetCartdetails();
                } else {
                  Notiflix.Notify.failure(obj3.data);
                  Notiflix.Loading.remove();
                }
              })
            );
          }
          // {  }
          if (Quantity == MinQuantity || Quantity == 1) {
            Notiflix.Loading.dots();
            PostApiCall.postRequest(
              {
                cartID: CartId,
                customerid:
                  localStorage.getItem("CustomerData") == null
                    ? null
                    : logindetails[0].fld_customerid,
                cartlineid: CartDetailsId,
                stateid: 0,
              },
              "DeleteCart"
            ).then((result) =>
              result.json().then((obj3) => {
                if (result.status == 200 || result.status == 201) {
                  Notiflix.Notify.success("Product is successfully deleted.");
                  Notiflix.Loading.remove();
                  setDecButton(false);
                  GetCartdetails();
                  if (ProductCart.length == 1) {
                    localStorage.removeItem("OrderDetails");
                  }
                  GetCartdetails();
                } else {
                  Notiflix.Notify.failure(obj3.data);
                  Notiflix.Loading.remove();
                }
              })
            );
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          role="presentation"
          class="icon icon-minus"
          fill="none"
          viewBox="0 0 10 2"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
      <quantity-input class="quantity-checkout" type="text">
        <input
          className="quantity__input"
          type="text"
          value={Quantity}
          min="0"
          id="Quantity-1"
          data-index="1"
        />
      </quantity-input>
      <button
        className="quantity__button no-js-hidden listing-page test"
        name="plus"
        type="button"
        onClick={() => {
          if (Quantity == MaxQuantity) {
            Notiflix.Notify.failure("The item has reached its maximum number.");
          } else {
            var login = localStorage.getItem("CustomerData");
            var logindetails = JSON.parse(login);
            var Order = localStorage.getItem("OrderDetails");
            var Orderdetails = JSON.parse(Order);
            Notiflix.Loading.dots();
            PostApiCall.postRequest(
              {
                orderdate: moment().format("lll"),
                itemdetails: `
                                                                                            [
                                                                                            {
                                                                                                "Item":${VariantId},
                                                                                                "Quantity":${+1}
                                                                                            }
                                                                                            ]
                                                                                            `,
                status: "INCART",
                customerid:
                  localStorage.getItem("CustomerData") == null
                    ? null
                    : logindetails[0].fld_customerid,
                createdon: moment().format("lll"),
                updatedon: moment().format("lll"),
                orderid:
                  localStorage.getItem("OrderDetails") == null
                    ? 0
                    : Orderdetails[0].order_id,
                updatedby:
                  localStorage.getItem("CustomerData") == null
                    ? null
                    : logindetails[0].fld_userid,
              },
              "AddToCartWebsite"
            ).then((result) =>
              result.json().then((obj3) => {
                if (result.status == 200 || result.status == 201) {
                  Notiflix.Notify.success("Product quantity updated.");
                  Notiflix.Loading.remove();
                  // getCartdetails();
                  if (localStorage.getItem("OrderDetails") == null) {
                    //
                    localStorage.setItem(
                      "OrderDetails",
                      JSON.stringify(obj3.data)
                    );
                  }
                  GetCartdetails();
                } else {
                  Notiflix.Notify.failure(obj3.data);
                  Notiflix.Loading.remove();
                }
              })
            );
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          role="presentation"
          class="icon icon-plus"
          fill="none"
          viewBox="0 0 10 10"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
      <div className=" checkout-delete ms-3">
        <cart-remove-button id="Remove-1" data-index="1" className="Remove-1">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              var login = localStorage.getItem("CustomerData");
              var logindetails = JSON.parse(login);
              Notiflix.Loading.dots();
              PostApiCall.postRequest(
                {
                  cartID: CartId,
                  customerid:
                    localStorage.getItem("CustomerData") == null
                      ? null
                      : logindetails[0].fld_customerid,
                  cartlineid: CartDetailsId,
                  stateid: 0,
                },
                "DeleteCart"
              ).then((result) =>
                result.json().then((obj3) => {
                  if (result.status == 200 || result.status == 201) {
                    Notiflix.Notify.success("Product is successfully deleted.");
                    Notiflix.Loading.remove();
                    GetCartdetails();
                    if (ProductCart.length == 1) {
                      localStorage.removeItem("OrderDetails");
                    }
                    GetCartdetails();
                  } else {
                    Notiflix.Notify.failure(obj3.data);
                    Notiflix.Loading.remove();
                  }
                })
              );
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="icon icon-remove"
              style={{
                height: "20px",
                width: "20px",
              }}
            >
              <path
                d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z"
                fill="currentColor"
              ></path>
              <path
                d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"
                fill="currentColor"
              ></path>
            </svg>
          </a>
        </cart-remove-button>
      </div>
    </div>
  );
}

export default AddRemoveItems1;
