import Notiflix from "notiflix";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import {
	Media
} from "reactstrap";
import PostApiCall from "../../Api/postApi";
class Chart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [
				{
					data: []
				}
			],
			options: {
				chart: {
					height: 350,
					type: "area",
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					type: "Month",
					categories: [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					],
				},
				tooltip: {
					x: {
						format: "₹",
					},
				},
			},
		};
	}
	componentDidMount() {
		Notiflix.Loading.init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.custom("");
		var customer = localStorage.getItem("CustomerData");
		var CustomerDetails = JSON.parse(customer);
		PostApiCall.postRequest(
			{
				WhereClause: ` where fld_customer_id = ${CustomerDetails[0].fld_customerid} `
			},
			"Get_DashboardCustomerYearlySales"
		).then((results) =>
			results.json().then((obj2) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						SalesData: obj2.data,
					});
					const categories = [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					];
					this.setState({
						categories: categories,
					});
					const data = new Array(12).fill(0);
					for (var i = 0; i < obj2.data.length; i++) {
						for (var j = 0; j < categories.length; j++) {
							if (obj2.data[i].month == categories[j]) {
								data[j] = obj2.data[i].total_sales
							}

						}
					}
					//
					this.setState({
						series: [
							{ name: "Order Summary", data: data },
						],
					});
					Notiflix.Loading.remove();
				}
			}))
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		return (
			<React.Fragment>
				<Card className="mini-stats-wid">
					<Card.Body className="viewcustomer-top-card-size">
						<Media>
							<Media body>
								<h5 className="mb-3 fw-bold">Order Summary Chart </h5>
								<ReactApexChart
									options={this.state.options}
									series={this.state.series}
									type="area"
									height={218}
								// width={100}
								/>
							</Media>
						</Media>
					</Card.Body>
				</Card>
			</React.Fragment>
		);
	}
}
export default Chart;
