import Notiflix from "notiflix";
import React from "react";
import { Badge, Card, Col, FormControl, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PostApiCall from '../../Api/postApi';
import "./ApplyCouponCheckout1.css";
function ApplyCouponCheckout1(props) {
    var OfferData = props.OfferData
    var cartID = props.cartID
    const CardBanners = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };
    var applyCoupon = (offerid) => {
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        if (offerid != null) {
            PostApiCall.postRequest(
                {
                    orderid: cartID,
                    couponid: offerid,
                    customerid:
                        localStorage.getItem("CustomerData") == null
                            ? null
                            : logindetails[0].fld_customerid,
                    status: "apply",
                },
                "ApplyOffer"
            ).then((result) =>
                result.json().then((obj3) => {
                    if (result.status == 200 || result.status == 201) {
                        Notiflix.Notify.success("Coupon applied successfully");
                        props.handleCoupon()
                        // props.handleCouponapply();
                        // getCartwebsite();
                    }
                })
            );
        } else {
        }
    }
    return (
        <div className="oderDetails p-3 pt-2">
            <div className="border-bottom">
                <span className="heading-font" >Apply Coupon</span>
            </div>  
            <InputGroup className="mt-3">
                <FormControl
                    placeholder="Enter code here"
                    aria-label="Recipient's username"
                    // aria-describedby="basic-addon2"
                    className="input-coupon"
                />
                <InputGroup.Text
                    className="Apply-but"
                    style={{
                        backgroundColor: "#b0acc6",
                        color: "white",
                        cursor: "pointer",
                    }}
                >
                    Apply
                </InputGroup.Text>
            </InputGroup>
            {OfferData.length != 0 ? (
                <MultiCarousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={CardBanners}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    className="apply-coupon-crousal"
                >
                    {OfferData.map((data) => {
                        return (
                            <>
                                <Card className="p-2 coupan-card mt-3">
                                    <Row>
                                        <Col lg={12}>
                                            <div className="d-flex justify-content-between">
                                                <Badge pill bg="secondary">
                                                    {data.fld_code}
                                                </Badge>{" "}
                                                {/* <span className="text-end" style={{ cursor: "pointer" }}>
                                      <AiFillEye />
                                    </span> */}
                                            </div>
                                            <p className="m-0">
                                                <strong style={{ fontSize: "14px" }}>
                                                    {data.fld_caption}
                                                </strong>
                                            </p>
                                            <div
                                                style={{ fontSize: "13px" }}
                                                className="mb-0"
                                            >
                                                {data.fld_name}
                                            </div>
                                            {/* </Col> */}
                                            {/* <Col lg={3}> */}
                                            <button
                                                onClick={() => {
                                                    applyCoupon(data.fld_offerid);
                                                    
                                                }}
                                                className="btn btn-theme mt-3"
                                            >
                                                Apply
                                            </button>
                                        </Col>
                                    </Row>
                                </Card>
                                {/* </MultiCarousel> */}
                            </>
                        );
                    })}
                </MultiCarousel>
            ) : (
                <>No Coupons available</>
            )}
            {/* </Accordion.Body>
                  </Accordion.Item>
                </Accordion> */}
        </div>
    )

}
export default ApplyCouponCheckout1