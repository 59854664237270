// var urlString = "http://localhost:8010/melhua-api/";
var urlString = "https://api.bechofy.in/melhua-api/";

const PostApiCall = {
  postRequest(userData, url) {
    return fetch(urlString + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        "x-auth-token":
          localStorage.getItem("access") != "undefined"
            ? JSON.parse(localStorage.getItem("access"))
            : null,
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
};
export default PostApiCall;
