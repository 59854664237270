import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { BiSolidQuoteLeft } from "react-icons/bi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import dumm1 from "../../../assets/images/testimonial/Testimonial 1.jpg";
import dumm2 from "../../../assets/images/testimonial/Testimonial 2.jpg";
import dumm3 from "../../../assets/images/testimonial/Testimonial 3.jpg";
import dumm4 from "../../../assets/images/testimonial/Testimonial 4.jpg";
import dumm5 from "../../../assets/images/testimonial/Testimonial 5.jpg";
import dumm6 from "../../../assets/images/testimonial/Testimonial 6.jpg";
import "./Testimonial.css";

function Testimonial() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section className="mt-md-5 mt-4">
        <div className="container-fluid container-md">
          <Row className="justify-content-center">
            <Col lg={10} className="text-center">
              <h2 className="testimonial-h2-content">Testimonial</h2>
              <h4 className="wow animate__animated animate__fadeInUp testimonial-h4-content">
                Glows from Happy Customers
              </h4>
            </Col>

            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={true}
              slidesToSlide={1}
              infinite={true}
              containerClass="carousel-container"
              // itemClass="carousel-item"
              className="mt-3 testimonial-craousel-arrow pb-md-5 pb-4"
            >
              <div className="mx-2 position-relative">
                <div className="row position-relative">
                  <div className="col-12 test">
                    <div className="testimonial-two-img1">
                      <img src={dumm1} className="w-100 h-100 common-border-radious" />
                    </div>
                  </div>

                  {/* ***** For Testimonial Content ****** */}
                  <div className="position-absolute main-testimonial-img">
                    <div className="content-box-testimonial px-3 w-75 position-relative">
                      <BiSolidQuoteLeft className="position-absolute testimonial-quote top-0 mt-1" />
                      <p className="my-0 testimonial-content">
                        Thanks, this looks so pretty. The cotton so soft and
                        comfortable. I will surely be getting more sheets from
                        you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-2 position-relative">
                <div className="row position-relative">
                  <div className="col-12 test">
                    <div className="testimonial-two-img1">
                      <img src={dumm2} className="w-100 h-100 common-border-radious" />
                    </div>
                  </div>

                  {/* ***** For Testimonial Content ****** */}
                  <div className="position-absolute main-testimonial-img">
                    <div className="content-box-testimonial px-3 w-75 position-relative">
                      <BiSolidQuoteLeft className="position-absolute testimonial-quote top-0 mt-1" />
                      <p className="my-0 testimonial-content">
                        Its so super soft, I am going to use it like an AC
                        blanket instead of bedspread. My green room looks so
                        cool with this one, love, love, love !
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-2 position-relative">
                <div className="row position-relative">
                  <div className="col-12 test">
                    <div className="testimonial-two-img1">
                      <img src={dumm3} className="w-100 h-100 common-border-radious" />
                    </div>
                  </div>

                  {/* ***** For Testimonial Content ****** */}
                  <div className="position-absolute main-testimonial-img">
                    <div className="content-box-testimonial px-3 w-75 position-relative">
                      <BiSolidQuoteLeft className="position-absolute testimonial-quote top-0 mt-1" />
                      <p className="my-0 testimonial-content">
                        Thanks for the delivery, love your idea of cloth bag
                        packing. I will surely be using this bag!
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-2 position-relative">
                <div className="row position-relative">
                  <div className="col-12 test">
                    <div className="testimonial-two-img1">
                      <img src={dumm4} className="w-100 h-100 common-border-radious" />
                    </div>
                  </div>

                  {/* ***** For Testimonial Content ****** */}
                  <div className="position-absolute main-testimonial-img">
                    <div className="content-box-testimonial px-3 w-75 position-relative">
                      <BiSolidQuoteLeft className="position-absolute testimonial-quote top-0 mt-1" />
                      <p className="my-0 testimonial-content">
                        Matches perfectly with my room, the sheet is so soft, we
                        love it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-2 position-relative">
                <div className="row position-relative">
                  <div className="col-12 test">
                    <div className="testimonial-two-img1">
                      <img src={dumm5} className="w-100 h-100 common-border-radious" />
                    </div>
                  </div>

                  {/* ***** For Testimonial Content ****** */}
                  <div className="position-absolute main-testimonial-img">
                    <div className="content-box-testimonial px-3 w-75 position-relative">
                      <BiSolidQuoteLeft className="position-absolute testimonial-quote top-0 mt-1" />
                      <p className="my-0 testimonial-content">
                        Thank you, amazing quality, my mother-in-law loves it !
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-2 position-relative">
                <div className="row position-relative">
                  <div className="col-12 test">
                    <div className="testimonial-two-img1">
                      <img src={dumm6} className="w-100 h-100 common-border-radious" />
                    </div>
                  </div>

                  {/* ***** For Testimonial Content ****** */}
                  <div className="position-absolute main-testimonial-img">
                    <div className="content-box-testimonial px-3 w-75 position-relative">
                      <BiSolidQuoteLeft className="position-absolute testimonial-quote top-0 mt-1" />
                      <p className="my-0 testimonial-content">
                        Love the quality, it fits well in our master bedroom.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>

            {/* ********** Testimonial Content **************** */}

            <div className="col-12 text-center mt-3">
              <p className="pb-0 mb-0 testimonial-p-content">
                Share our love for fine quality!
                <br /> We take great pride in creating our limited-edition
                design of hand block printed products.
              </p>
            </div>
          </Row>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
