import React, { useContext } from "react";
import { Card, Col } from "react-bootstrap";
import { FaMobileAlt } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { MdAddLocationAlt, MdEditLocationAlt } from "react-icons/md";
import "react-multi-carousel/lib/styles.css";
import { store } from "../../context/store";
import "./CheckoutAddress1.css";
function CheckoutAddress1(props) {
  const AddressType = props.AddressType;
  const {
    setChangeAddressType,
    setChangeAddress,
    setAddressCard,
    setShowAddressSelectCheckout,
  } = useContext(store);
  const SelectedAddress = props.SelectedAddress;
  return (
    <div className="Shipping-Address p-3 pt-2 h-100">
      <Col lg={12} className="border-bottom d-flex justify-content-between">
        <span className="heading-font" style={{ lineHeight: "35px" }}>
          {AddressType}
        </span>
        <div
          className="fs-4"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setShowAddressSelectCheckout(true);
            setChangeAddressType(AddressType);
          }}
        >
          <MdEditLocationAlt />
        </div>
      </Col>
      {SelectedAddress.length == 0 ? (
        <>
          {" "}
          <div className="text-center my-3">
            <h6 className="fw-bold">Your {AddressType} is empty.</h6>
            <p className="small">Add {AddressType} for easy checkout.</p>
          </div>
          <div className="text-center my-3">
            <button
              className="mt-4 btn shop-btn px-4"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowAddressSelectCheckout(true);
                setChangeAddressType(AddressType);
              }}
            >
              <div className="">
                <MdAddLocationAlt />
              </div>
              <div>Add address</div>
            </button>
          </div>
        </>
      ) : (
        SelectedAddress.map((data, i) => {
          return (
            <>
              <Card className="mt-4">
                <Card.Body>
                  <div className="addressCard">
                    <p>
                      <b>{data.fld_contact_name}</b>
                    </p>
                    <p>
                      {data.fld_address_1},{data.fld_address_2},{data.CityName}-
                      {data.fld_pincode},
                    </p>
                    <p>
                      {data.StateName},{data.CountryName}
                    </p>
                    <p
                      style={{
                        display: data.fld_landmark == null ? "none" : "block",
                      }}
                    >
                      <ImLocation2 /> {data.fld_landmark}
                    </p>
                    <p style={{ marginBottom: "0" }}>
                      <FaMobileAlt />
                      &nbsp; {data.fld_contact_number}
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </>
          );
        })
      )}
    </div>
  );
}
export default CheckoutAddress1;
