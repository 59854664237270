import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import "react-multi-carousel/lib/styles.css";
import { Link, useLocation } from "react-router-dom";
import AddToCard1 from "../AddToCart/AddToCart1";
import AddToCartQty from "../AddToCartQty/AddToCartQty";
import "./Card1.css";
import { store } from "../../context/store";

function Card1(props) {
  // const [isInCart, setIsInCart] = useState();
  const location = useLocation();
  const product = props.product;
  // console.log(props.isInCart)
  function getExtension(filename) {
    return filename?.split(".").pop();
  }
  return (
    <div className="mx-sm-2 mx-0">
      <Col className="bg-light">
        <div className="">
          {getExtension(product.VariantImage?.split("#")[0].toLowerCase()) ===
          "mp4" ? (
            <Link to={`/product-detail/${props.product.fld_variantid}`}>
              <video
                src={product.VariantImage.split("#")[0]}
                autoPlay
                loop
                autoFocus
                autoCorrect="true"
                muted
                className="img-fluid w-100"
              />
            </Link>
          ) : (
            <a href={`/product-detail/${props.product.fld_variantid}`}>
              <Image
                className="img-fluid w-100 product-shop-image card-size-details-page"
                src={product.VariantImage?.split("#")[0]}
              />
            </a>
          )}
        </div>
        <div className="p-sm-3 p-2">
          {props.showitemname === "true" ? (
            <>
              <h5 className="mb-lg-2 mb-sm-3 mb-1 fw-bold">
                <Link
                  className="text-dark text-decoration-none shop-item-heading"
                  to={`/product-detail/${props.product.fld_variantid}`}
                >
                  {product.fld_variantname}
                </Link>
              </h5>
              <p className="mb-0 category-name">
                {product.fld_categoryname} / {product.fld_subcategoryname}
              </p>
            </>
          ) : (
            ""
          )}

          {props.showprice === "true" ? (
            <h5 className="my-lg-2 my-sm-3 mt-1 mb-2 d-flex justify-content-between">
              <p className="mb-0 shop-card-price-size">
                <span>
                  <BiRupee />
                  {product.fld_website_discount_price}
                </span>{" "}
                {product.fld_website_discount_price !== product.fld_mrp ? (
                  <span className="mrp-price">
                    <BiRupee />
                    {product.fld_mrp}
                  </span>
                ) : (
                  ""
                )}
              </p>
              {product.fld_website_discount_price !== product.fld_mrp ? (
                <span>{product.fld_discount_percent}% OFF</span>
              ) : (
                ""
              )}
            </h5>
          ) : (
            ""
          )}
          {props.showaddrtocart === "true" ? (
            <div className="mt-lg-3">
              <Row className="justify-content-end">
                <Col lg={12}>
                  {/* {console.log(product.item_count_cart)} */}
                  {props.isInCart ? (
                    <AddToCartQty
                      itemid={product.fld_variantid}
                      quantity={product.item_count_cart}
                      cartlineid={props.isInCart.fld_cart_dtl_id}
                    />
                  ) : (
                    <>
                      {product.available_stock == "In Stock" ? (
                        <AddToCard1 itemid={product.fld_variantid} />
                      ) : (
                        <>
                          <button
                            type="button"
                            disabled
                            class="btn w-100 out-of-stock-btn"
                          >
                            Out of Stock
                          </button>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </div>
      </Col>
    </div>
  );
}
export default Card1;
