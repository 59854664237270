import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import AboutImage from "../assets/images/image.png";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import Notiflix from "notiflix";
import PostApiCall from "../Api/postApi";
import { Skeleton } from "antd";

function Contactpage() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [numRegex, setNumRegex] = useState(/^[0-9]*$/);
  const [emailRegex, setEmailRegex] = useState(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const [loading, setLoading] = useState(true);

  const onPostForm = () => {
    Notiflix.Loading.circle();
    PostApiCall.postRequest(
      {
        name: name,
        mobile: mobile,
        email: email,
        message: message,
      },
      "ContactUsMailer"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success(
            "Your form submitted successfully. Our team will get back to you shortly."
          );
          setName("");
          setEmail("");
          setMobile(null);
          setMessage("");
        }
      })
    );
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
    //I mock loading with setTimeout,the skeleton disappears in 5 seconds
  }, []);
  return (
    <>
      <section>
        <HeroBanner />
        <div className="container-fluid container-md">
          {loading ? (
            <Row>
              <Col lg={7}>
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="large"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="large"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="large"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="large"
                />

                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3 w-75"
                  block
                  size="small"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3 w-75"
                  block
                  size="small"
                />
              </Col>

              <Col lg={5}>
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="medium"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="medium"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="medium"
                />

                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="medium"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="large"
                />
                <Skeleton.Button
                  active={true}
                  className="pb-4 mb-3 w-25"
                  block
                  size="large rounded"
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="mb-3">
                <Col lg={7}>
                  <p>
                    If you wish to share feedback or have any queries related to
                    our products, please get in touch with our team at the below
                    mentioned co-ordinates.
                  </p>
                  <p>
                    Please note, in case of billing/payment related queries do
                    mention your complete transaction details with relevant
                    information for a quick resolution.
                  </p>
                  <p>
                    For general queries related to payment/ exchange/refund
                    /shipping please refer to the FAQ's (Frequently Asked
                    Questions) page.
                  </p>
                  <p>
                    Our team will endeavor to reply to your requests and queries
                    for new orders or bulk supplies within 2 working days.
                  </p>
                  <p>
                    <strong>Customer Care team is available </strong>
                    Monday to Saturday, between 9:00AM to 6:00PM IST.
                  </p>
                  <div className="contact-links">
                    <p>
                      <strong>Email</strong>:{" "}
                      <a href="mailto:sales@meluhahome.com">
                        {" "}
                        sales@meluhahome.com
                      </a>
                    </p>
                    <p>
                      <strong>Contact</strong>:{" "}
                      <a href="tel:+919818246677"> +91 9818246677</a>
                    </p>
                    <p>
                      <strong>Address</strong>: 34, Sangram Colony, C-Scheme,
                      Jaipur - 302001, India
                    </p>
                  </div>
                </Col>
                <Col lg={5}>
                  {/* <Form> */}
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Contact number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Contact number"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    className="btn shop-btn px-5"
                    type="submit"
                    onClick={() => {
                      if (name != "") {
                        if (email != "") {
                          if (emailRegex.test(email)) {
                            if (mobile != "") {
                              if (mobile.length == 10) {
                                if (message != "") {
                                  onPostForm();
                                } else {
                                  Notiflix.Notify.failure(
                                    "Please write your message."
                                  );
                                }
                              } else {
                                Notiflix.Notify.failure(
                                  "Please enter valid mobile number."
                                );
                              }
                            } else {
                              Notiflix.Notify.failure(
                                "Please enter mobile number."
                              );
                            }
                          } else {
                            Notiflix.Notify.failure(
                              "Please enter valid email."
                            );
                          }
                        } else {
                          Notiflix.Notify.failure(
                            "Please enter email address."
                          );
                        }
                      } else {
                        Notiflix.Notify.failure("Please enter your name.");
                      }
                    }}
                  >
                    Send
                  </Button>
                  {/* </Form> */}
                </Col>
              </Row>
            </>
          )}
        </div>
        {/* <HeroBanner />
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={10} className="text-center">
              <p className="main-text">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi
                ut aliquip ex ea commodo consequat.
              </p>
            </Col>
          </Row>
          <Row className="py-lg-5 py-5 justify-content-start align-items-start">
            <Col lg={6}>
              <Image src={AboutImage} className="img-fluid w-100" alt="" />
            </Col>
            <Col lg={6}>
              <h4>Write to us</h4>
              <p className="main-text">
                Ellamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter Email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter Message"
                  />
                </Form.Group>
                <Button className="btn btn-theme" type="submit">
                  Send
                </Button>
              </Form>
            </Col>
          </Row>
        </Container> */}
      </section>
    </>
  );
}

export default Contactpage;
