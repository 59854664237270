import React from 'react';
import "./HeroBanner.css";
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useParams } from "react-router-dom";

const HeroBanner = () => {
  let location = useLocation();
  let params = useParams(); 
  return (
    <div className="py-lg-0 py-4 mb-5 hero-parallax">
      <Container>
        <Row className="align-items-center pt-lg-3 pb-lg-3 pt-3 pb-3 justify-content-center">
          <Col lg={12} className="text-center px-lg-5 px-2">
            <h1 className="main-title wow animate__animated animate__fadeInUp text-capitalize mb-0">
              {params.verticalname != undefined ? params.verticalname.replace(/-/g, " ") :
                params.subcategoriesname != undefined ? params.subcategoriesname.replace(/-/g, " ") :
                  params.categoryname != undefined ? params.categoryname.replace(/-/g, " ") :
                    params.subcategoryname != undefined ? params.subcategoryname.replace(/-/g, " ") : <h2 className="main-title wow animate__animated animate__fadeInUp text-capatilize">
                      {window.location.pathname.replace('/', " ").replace(/-/g, " ")}
                    </h2>}
            </h1>
          </Col>
        </Row>
      </Container>
      <div className="overlay"></div>
    </div>
  );
}

export default HeroBanner;