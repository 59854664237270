import React, { Component, useContext, useEffect, useState } from "react";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { store } from "../../context/store";
import "./AddAddress1.css";
// import "../MyAccount/Myaccount.css";
//import CouponIcon from "../../assets/img/couponicon.webp";
import Notiflix from "notiflix";
import GetApiCall from "../../Api/getApi";
import PostApiCall from "../../Api/postApi";
//import { ThemeConsumer } from "styled-components";
//import Footer from '../../components/Footer/Footer';

const AddaddressForm = ({ closeCanvas }) => {
  const { AddressCard, setAddressCard, setShowAddressSelectCheckout } =
    useContext(store);
  const [loading, setLoading] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [title, setTitle] = useState("Home");
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [area, setArea] = useState(null);
  const [flatNo, setFlatNo] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [numRegex, setNumRegex] = useState(/^[0-9]*$/);
  const [GSTNumber, setGSTNumber] = useState(null);
  const [primaryFlag, setPrimaryFlag] = useState("No");
  useEffect(() => {
    var Address = localStorage.getItem("AddressData");
    var AddressData = JSON.parse(Address);
    if (localStorage.getItem("AddressData")) {
      setName(AddressData.fld_contact_name);
      setMobile(AddressData.fld_contact_number);
      setFlatNo(AddressData.fld_address_1);
      setArea(AddressData.fld_address_2);
      setLandmark(AddressData.fld_landmark);
      setGSTNumber(AddressData.fld_gstin);
      setPincode(AddressData.fld_pincode);
    }
    localStorage.removeItem("currentPage");
    localStorage.removeItem("SelectAddress");
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        setCountryData(obj.data);
        if (obj.data.length != 0) {
          if (localStorage.getItem("AddressData")) {
            setCountryId(AddressData.fld_country);
            setCountry(AddressData.CountryName);
          } else {
            setCountryId(101);
            setCountry(obj.data[102].label);
          }
        }
        PostApiCall.postRequest(
          {
            countryid: 101,
          },
          "GetState"
        ).then((results) =>
          results.json().then((objstate) => {
            if (results.status == 200 || results.status == 201) {
              if (objstate.data.length != 0) {
                if (localStorage.getItem("AddressData")) {
                  setStateId(AddressData.fld_state);
                  setStateData(objstate.data);
                  setState(AddressData.StateName);
                } else {
                  setStateId(objstate.data[0].value);
                  setStateData(objstate.data);
                  setState(objstate.data[0].label);
                }
              }
              PostApiCall.postRequest(
                {
                  stateid: AddressData
                    ? AddressData.fld_state
                    : objstate.data[0].value,
                },
                "GetCity"
              ).then((resultscity) =>
                resultscity.json().then((objcity) => {
                  if (resultscity.status == 200 || resultscity.status == 201) {
                    if (objcity.data.length != 0) {
                      if (localStorage.getItem("AddressData")) {
                        setCityId(AddressData.fld_city);
                        setCityData(objcity.data);
                        setCity(AddressData.CityName);
                      } else {
                        setCityId(objcity.data[0].value);
                        setCityData(objcity.data);
                        setCity(objcity.data[0].label);
                      }
                    }
                  }
                })
              );
            }
          })
        );
      })
    );
  }, []);

  const onChangeCountry = (country) => {
    setCountryId(country.target.value);
    setCountry(countryData[parseInt(country.target.value) + 1].label);
    PostApiCall.postRequest(
      {
        countryid: country.target.value,
        Country: countryData[parseInt(country.target.value) + 1].label,
      },
      "GetState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setStateId(obj.data[0].value);
          setState(obj.data[0].label);
          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value,
            },
            "GetCity"
          ).then((resultscity) =>
            resultscity.json().then((objcity) => {
              if (resultscity.status == 200 || resultscity.status == 201) {
                setCityData(objcity.data);
                setStateData(obj.data);
                if (objcity.data.length > 0) {
                  setCityId(objcity.data[0].value);
                  setCity(objcity.data[0].label);
                }
              }
            })
          );
        }
      })
    );
  };
  const onChangeState = (state) => {
    setStateId(state.target.value);
    for (var i = 0; i < Object.keys(stateData).length; i++) {
      if (stateData[i].value == state.target.value) {
        setState(stateData[i].label);
      }
    }
    PostApiCall.postRequest(
      {
        stateid: state.target.value,
      },
      "GetCity"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length > 0) {
            setCityData(obj.data);
            setCityId(obj.data[0].value);
            setCity(obj.data[0].label);
          }
        }
      })
    );
  };
  const onChangeCity = (city) => {
    setCityId(city.target.value);
    for (var i = 0; i < Object.keys(cityData).length; i++) {
      if (cityData[i].value == city.target.value) {
        setCity(cityData[i].label);
      }
    }
  };

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-top",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);

  return (
    <>
      <Container className="mt-0 p-0">
        <div className="row">
          <div className="col-md-12 center">
            <div className="form-group mb-3">
              <label for="validationCustom01">
                Address Title<span className="mandatory">*</span>
              </label>
              <br />
              <label className="radio-inline">
                <input
                  type="radio"
                  name="optradio"
                  checked={title === "Office" ? true : false}
                  onChange={() => {
                    setTitle("Office");
                  }}
                />
                &nbsp; Office
              </label>
              <label className="radio-inline" style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  name="optradio"
                  checked={title === "Home" ? true : false}
                  onChange={() => {
                    setTitle("Home");
                  }}
                />
                &nbsp; Home
              </label>
            </div>
          </div>
          <Row>
            <Col xs={12} s={12} md={12} lg={6}>
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  className="input"
                  value={name}
                  onChange={(text) => {
                    setName(text.target.value);
                  }}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} s={12} md={12} lg={6}>
              <FloatingLabel
                controlId="floatingInput"
                label="Mobile Number"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  className="input"
                  value={mobile}
                  onChange={(mobile) => {
                    if (mobile.target.value.length <= 10) {
                      setMobile(mobile.target.value.replace(/\D/g, ""));
                    }
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={12} s={12} md={12} lg={6}>
              <FloatingLabel
                controlId="floatingInput"
                label="Flat/House/Building No."
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  className="input"
                  value={flatNo}
                  onChange={(text) => {
                    setFlatNo(text.target.value);
                  }}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} s={12} md={12} lg={6}>
              <FloatingLabel
                controlId="floatingInput"
                label="Area/Street/Sector/Village"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  className="input"
                  value={area}
                  onChange={(text) => {
                    setArea(text.target.value);
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={12} s={12} md={12} lg={6}>
              <FloatingLabel
                controlId="floatingInput"
                label="Lankmark (optional)"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  className="input"
                  value={landmark}
                  onChange={(text) => {
                    setLandmark(text.target.value);
                  }}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId="floatingInput"
                label="GST Number (optional)"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  className="input"
                  value={GSTNumber}
                  onChange={(text) => {
                    setGSTNumber(text.target.value);
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId="floatingInput"
                label="Pincode"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Pincode"
                  className="input"
                  value={pincode}
                  onChange={(pin) => {
                    if (pin.target.value.length <= 6) {
                      setPincode(pin.target.value.replace(/\D/g, ""));
                    }
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={12} s={12} md={12} lg={4}>
              <Form.Label htmlFor="basic-url">Country</Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="mb-3 pd-country"
                value={countryId}
                onChange={onChangeCountry}
              >
                {countryData.map((schedule) => (
                  <option key={schedule.label} value={schedule.value}>
                    {schedule.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={12} s={12} md={12} lg={4}>
              <Form.Label htmlFor="basic-url">State</Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="mb-3 pd-country"
                value={stateId}
                onChange={onChangeState}
              >
                {stateData.map((schedule) => (
                  <option key={schedule.label} value={schedule.value}>
                    {schedule.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={12} s={12} md={12} lg={4}>
              <Form.Label htmlFor="basic-url">City</Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="mb-3 pd-country"
                value={cityId}
                onChange={onChangeCity}
              >
                {cityData.map((schedule) => (
                  <option key={schedule.label} value={schedule.value}>
                    {schedule.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <label className="radio-inline">
              <input
                type="radio"
                name="defautaddress"
                checked={primaryFlag === "Yes" ? true : false}
                onChange={() => {
                  setPrimaryFlag("Yes");
                }}
              />
              &nbsp; Mark As Deafult Address
            </label>
          </Row>
          <Row>
            <Col lg={12}>
              <button
                className="btn btn-theme change-addressButton shop-btn mt-4"
                type="button"
                onClick={(e) => {
                  e.preventDefault();

                  if (title != null) {
                    if (name != null) {
                      if (mobile != "") {
                        if (numRegex.test(mobile) && mobile.length == 10) {
                          if (flatNo != null) {
                            if (area != null) {
                              if (country != null) {
                                if (state != null) {
                                  if (city != null) {
                                    if (pincode != null) {
                                      if (
                                        numRegex.test(pincode) &&
                                        pincode.length == 6
                                      ) {
                                        if (country != null) {
                                          if (state != null) {
                                            if (city != null) {
                                              setLoading(true);
                                              const login =
                                                localStorage.getItem(
                                                  "CustomerData"
                                                );
                                              const loginDetails =
                                                JSON.parse(login);
                                              const customer_Address =
                                                localStorage.getItem(
                                                  "AddressData"
                                                );
                                              const edit_customer_address =
                                                JSON.parse(customer_Address);
                                              Notiflix.Loading.dots("");
                                              PostApiCall.postRequest(
                                                {
                                                  CUSTOMERID:
                                                    loginDetails[0]
                                                      .fld_customerid,
                                                  addressid: customer_Address
                                                    ? edit_customer_address.fld_address_id
                                                    : addressId,
                                                  title: title,
                                                  name: name,
                                                  mobileNo: mobile,
                                                  area: area,
                                                  flatno: flatNo,
                                                  landmark: landmark,
                                                  gstNumber: GSTNumber,
                                                  country: countryId,
                                                  state: stateId,
                                                  city: cityId,
                                                  pincode: pincode,
                                                  action: customer_Address
                                                    ? "Update"
                                                    : "INSERT",
                                                  primaryFlag: primaryFlag,
                                                },
                                                "AddCustomerAddress"
                                              ).then((result) =>
                                                result.json().then((obj) => {
                                                  if (
                                                    result.status == 200 ||
                                                    result.status == 201
                                                  ) {
                                                    setLoading(false);
                                                    closeCanvas();
                                                    Notiflix.Loading.remove();
                                                    Notiflix.Notify.success(
                                                      "Address successfully Added"
                                                    );
                                                    setShowAddressSelectCheckout(
                                                      false
                                                    );
                                                    localStorage.removeItem(
                                                      "AddressData"
                                                    );
                                                    //window.location.href("/checkout")
                                                  } else {
                                                    setLoading(false);
                                                    Notiflix.Loading.remove();
                                                    Notiflix.Notify.failure(
                                                      obj.data
                                                    );
                                                  }
                                                })
                                              );
                                            } else {
                                              Notiflix.Notify.failure(
                                                "Please enter city"
                                              );
                                            }
                                          } else {
                                            Notiflix.Notify.failure(
                                              "Please enter state"
                                            );
                                          }
                                        } else {
                                          Notiflix.Notify.failure(
                                            "Please select country"
                                          );
                                        }
                                      } else {
                                        Notiflix.Notify.failure(
                                          "Please enter valid pincode"
                                        );
                                      }
                                    } else {
                                      Notiflix.Notify.failure(
                                        "Please enter pincode"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please enter city"
                                    );
                                  }
                                } else {
                                  Notiflix.Notify.failure("Please enter state");
                                }
                              } else {
                                Notiflix.Notify.failure(
                                  "Please select country"
                                );
                              }
                            } else {
                              Notiflix.Notify.failure(
                                "Please enter Area/Colony/Street/Sector/Village"
                              );
                            }
                          } else {
                            Notiflix.Notify.failure(
                              "Please enter Flat/House/Company/Building/Apartment No."
                            );
                          }
                        } else {
                          Notiflix.Notify.failure(
                            "Please enter valid mobile number"
                          );
                        }
                      } else {
                        Notiflix.Notify.failure("Please enter mobile number");
                      }
                    } else {
                      Notiflix.Notify.failure("Please enter your Full Name");
                    }
                  } else {
                    Notiflix.Notify.failure("Please select Address Title");
                  }
                }}
              >
                {loading == true ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  "Save Address"
                )}
              </button>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default AddaddressForm;
