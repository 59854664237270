import Parser from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
// import { BiCommentDetail, BiLike } from "react-icons/bi";
// import { BsFillReplyFill } from "react-icons/bs";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaWhatsappSquare,
} from "react-icons/fa";
import PostApiCall from "../../Api/postApi";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Skeleton } from "antd";

// import "./Blog.css";
// import AddNewComment from "./NewComment";
const BlogDetail = () => {
  const [blogData, setBlogData] = useState([]);
  let params = useParams();
  // const [done, setdone] = useState(true);
  // const [blogCommentsData, setBlogCommentsData] = useState([]);
  // const [showCommentArea, setShowCommentArea] = useState(false);
  // const [comment, setComment] = useState("");
  // const [commentid, setCommentid] = useState(null);
  // const [like, setLike] = useState(null);
  // const [replyComment, setReplyComment] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: ` where fld_id = ${params.id}`,
      },
      "GetBlogArticleById"
    ).then((results) => {
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setBlogData(obj.data);
          setLoading(false);
        }
      });
    });
    // getBlogComments()
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  // const addComments = () => {
  //     PostApiCall.postRequest({
  //         id: commentid == null ? null : commentid,
  //         userid: (JSON.parse(localStorage.getItem("CustomerData")))[0].fld_customerid,
  //         comment: comment,
  //         blogid: JSON.parse(localStorage.getItem("blogId"))
  //     }, "AddUpdateBlogComments").then(
  //         (results) => {
  //             results.json().then((obj) => {
  //                 if (results.status == 200 || results.status == 201) {
  //                     Notiflix.Notify.success("Comment successfully added")
  //                     getBlogComments()
  //                 }
  //             });
  //         }
  //     );
  // }
  // const getBlogComments = () => {
  //     PostApiCall.postRequest(
  //         {
  //             blogid: JSON.parse(localStorage.getItem("blogId")),
  //         },
  //         "GetBlogComments"
  //     ).then((results) =>
  //         // const objs = JSON.parse(result._bodyText)
  //         results.json().then((obj) => {
  //             Notiflix.Loading.dots("Please wait...");
  //             if (results.status == 200 || results.status == 201) {
  //                 if (obj.data.length != 0) {
  //                     setBlogCommentsData(obj.data);
  //                     setComment("");
  //                     Notiflix.Loading.remove()
  //                 }
  //             }
  //             Notiflix.Loading.remove()
  //         }
  //         )
  //     )
  // }
  return (
    <Container fluid className="px-0 blogdetailoverflow mt-4">
      <Container className="mt-4">
        {blogData.length <= 0 ? (
          <Row>
            <Col lg={12} className="my-4 blog-image-skeleton">
              <Skeleton.Image className="imgblogcontent h-100 w-100" />
            </Col>
            <Col lg={12} className="blog_heading">
              <Skeleton.Input
                active={true}
                className="mb-2 w-75"
                block
                size="medium"
              />
            </Col>
            <Col lg={12}>
              <Row className="d-flex align-items-end justify-content-between mb-4">
                <Col lg={2} className="">
                  <Skeleton.Input active={true} block size="ssmall" />
                  <Skeleton.Input active={true} block size="ssmall" className="mt-2" />
                </Col>
                <Col lg={4} className="d-flex justify-content-between">
                  <div className="me-2">
                    <Skeleton.Button active="active" size="medium" shape="square" block />
                  </div>
                  <div className="me-2">
                    <Skeleton.Button active="active" size="medium" shape="square" block />
                  </div>
                  <div className="me-2">
                    <Skeleton.Button active="active" size="medium" shape="square" block />
                  </div>
                  <div className="">
                  <Skeleton.Button active="active" size="medium" shape="square" block />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={12} className="blog_heading">
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="medium"
              />
            </Col>
            <Col lg={12} className="blog_heading">
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="medium"
              />
            </Col>
            <Col lg={12} className="blog_heading">
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="medium"
              />
            </Col>
            <Col lg={12} className="blog_heading">
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="medium"
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              {blogData.map((data, i) => {
                return (
                  <div>
                    <div className="col-12 my-4 blog-image">
                      <img
                        className="imgblogcontent w-100"
                        src={data.fld_blogimage}
                      />
                    </div>
                    <Col lg={12} className="blog_heading">
                      <h4>{data.fld_short_description.split("$")[0]}</h4>
                    </Col>
                    <Row className="d-flex justify-content-between">
                      <Col lg={6} className="">
                        <h6 className="blogdetailsdatefont1">
                          Updated on - {moment(data.fld_updatedon).format("ll")}
                        </h6>
                        <h6 className="blogdetailsdatefont1">
                          Posted by -
                          <span className="mx-2">{data.fld_username}</span>
                        </h6>
                      </Col>
                      <Col
                        lg={6}
                        className="blogdetail-socialicon float-right justify-content-end text-lg-end text-left social-medialink">
                        <FacebookShareButton
                          url={`https://meluhahome.com/blogdetails/${data.fld_id}`}
                          quote={data.fld_variantname}>
                          <FaFacebookSquare className="blogdeatilicon" />
                        </FacebookShareButton>
                        <span className="ms-4">
                          <LinkedinShareButton
                            url={`https://meluhahome.com/blogdetails/${data.fld_id}`}
                            quote={data.fld_variantname}>
                            <FaLinkedin className="blogdeatilicon" />
                          </LinkedinShareButton>
                        </span>
                        <span className="ms-4">
                          <TwitterShareButton
                            url={`https://meluhahome.com/blogdetails/${data.fld_id}`}
                            quote={data.fld_variantname}>
                            <FaTwitterSquare className="blogdeatilicon" />
                          </TwitterShareButton>
                        </span>
                        <span className="ms-4">
                          <WhatsappShareButton
                            url={`https://meluhahome.com/blogdetails/${data.fld_id}`}
                            quote={data.fld_variantname}>
                            <FaWhatsappSquare className="blogdeatilicon" />
                          </WhatsappShareButton>
                        </span>
                      </Col>
                    </Row>
                    <div className="col-12">
                      <p>
                        {Parser(
                          ("<p>" + data.fld_content + "</p>")
                            .replace(/font-family/g, "")
                            .replace(/<br\/?>/gi, " ")
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
              {/* ----------------for comments--------- */}
              <div className="col-12 mt-4 mb-2">
                <div className="row">
                  <div className="col-12">
                    {/* {blogData.map((data, i) => {
                                    return (
                                        <>
                                            <a href="#" className="d-flex blogdetaillike me-2">
                                                <BiLike className="svgicon me-1" style={{ fontSize: '24px' }} />
                                                <sup className="my-auto" style={{ display: data.fld_likecount == 0 ? "none" : "block" }}>{data.fld_likecount}</sup>
                                            </a>
                                            <a className="d-flex blogdetaillike me-2" onClick={() => {
                                                setShowCommentArea(true);
                                                setCommentid(null);
                                            }}>
                                                <BiCommentDetail className="svgicon me-1" style={{ fontSize: '24px' }} />
                                                <p className="my-auto ms-2" style={{ display: data.fld_commentcount == 0 ? "none" : "block" }}>{data.fld_commentcount}{" "}Comments:</p>
                                            </a>
                                        </>
                                    );
                                })} */}
                    {/* <Accordion defaultActiveKey="0" flush>
                                    <Accordion.Item eventKey="0" className="bg-light">
                                        <Accordion.Header>
                                            <BiCommentDetail
                                                className="svgicon"
                                                style={{ fontSize: "24px" }}
                                            />
                                            <p className="mb-0 fw-bold">Comments</p>
                                            {blogData.map((data, i) => {
                                                return (
                                                    <>
                                                        <p className="my-auto ms-2" style={{ display: data.fld_commentcount == 0 ? "none" : "block", }} >
                                                            {data.fld_commentcount} Comments:
                                                        </p></>)
                                            })}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {blogCommentsData.length != 0 ? (
                                                blogCommentsData.map((data, i) => {
                                                    return (
                                                        <div className="row commentbox">
                                                            <div className="col-12 mb-2">
                                                                <img className="profile_comment_img" src={data.fld_image}></img>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h5 className="blogdetailsunamefont blogcommentusername">
                                                                        {data.fld_username}
                                                                    </h5>
                                                                    <p className="blogdetailsdatefont blogcommentuser">
                                                                        {moment(data.fld_updatedon).format(
                                                                            "ll"
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                key={i}
                                                                className="col-12 box-comment"
                                                            >
                                                                {data.fld_comment}
                                                                {data.fld_id}
                                                            </div>
                                                            <a
                                                                className="reply-btn"
                                                                onClick={(e) => {
                                                                    setReplyComment(true);
                                                                }}
                                                            >
                                                                <BsFillReplyFill /> Reply
                                                            </a>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        replyComment == true
                                                                            ? "block"
                                                                            : "none",
                                                                }}
                                                            >
                                                                <AddNewComment />
                                                                {this.state.BlogCommentsData.length > 0 ? this.state.BlogCommentsData.map((data) => (
                                    <CommentsList comment={data} from="main" allComments={data.child_comments} />
                                  )) : ""}
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <>
                                                    <AddNewComment />
                                                </>
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion> */}
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
      </Container>
    </Container>
  );
};
export default BlogDetail;
