
import React from 'react';
import { Col, Image } from 'react-bootstrap';
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import "./Card2.css";
import { Skeleton } from 'antd';

function Card2(props) {
  const product = props.product;


  return (
    <>

      <div className="mx-md-2 mx-1 phone-view-dots">
        <a href={props.path} className="text-decoration-none text-dark">
          <Col className="text-center bg-light ">
            <div className="p-0 m-0 category-tab-image-space">
              <Image src={product.fld_image} className="img-fluid w-100 product-image home-category" />
            </div>
            <div className="p-sm-3 p-2">
              <h6 className="mb-lg-2 mb-md-3 mb-0 fw-bold">
                {product.fld_name}
              </h6>
            </div>

            {/* <button
              className="shop-btn mt-2 mb-3 px-5 py-2"
            >
              <p className="m-0 p-0 product-p-shopnow">Shop Now</p>
            </button> */}
          </Col>
        </a>
      </div>

    </>
  );
}
export default Card2;