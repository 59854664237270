import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Offcanvas, Row } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { FaMobileAlt } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { MdAddLocationAlt, MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Media } from "reactstrap";
import PostApiCall from "../../Api/postApi";
import Searchicon from "../../assets/images/search-engine.png";
import AddaddressForm from "../../components/Address/AddAddress1";
// import backicon from '../../assets/images/backicon.png';
// import Blockicon from "../../assets/images/dashboardimages/blocked.png";
// import user1 from "../../assets/images/helpcenter.png";
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomerCardHistory from "./CustomerCardHistory";
import "./CustomerProfile.css";
// import CustomerFrequentlyBought from "./CustomerFrequentlyBought";
import { store } from "../../context/store";
import CustomerOrderList from "./CustomerOrderList";
import OrderGraph from "./OrderSummary";
// skeleton
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import "../../pages/Skeleton.css";
var latestdate = [];
const GalleryStyles = styled.div`
  .gallery__grid {
    display: grid;
    ${"" /* gap: 3rem; */}
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    justify-content: center;
    padding: 50px 0 0 0;
  }
  .gallery__title {
    font-size: 2rem;
    padding: 3%;
    text-align: center;
  }
  .item {
    margin: auto;
    border: 3px solid var(--gray-1);
  }
  .item__btns {
    display: flex;
    justify-content: space-between;
    button {
      font-size: 1.125rem;
      background-color: var(--gray-1);
      padding: 0.2rem 0.5rem;
      height: 3rem;
      border-radius: 8px;
      font-weight: bolder;
    }
  }
  .item-img {
    width: 304px;
    height: 140px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      object-fit: contain;
    }
  }
  .item-title {
    font-size: 1rem;
    height: 82px;
    text-align: center;
    margin-bottom: 1rem;
  }
  .item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .item-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    width: 60px;
  }
  .item__btnadd {
    border: 2px solid var(--red-1);
    color: var(--red-1);
  }
  .item-price {
    font-size: 2.5rem;
    color: var(--blue-1);
    text-align: center;
    margin-bottom: 1rem;
  }
  .item__btnbuy {
    border: 2px solid var(--red-1);
    background-color: var(--red-1) !important;
    color: var(--gray-1);
  }
  .item-start {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid yellow;
    svg {
      font-size: 1rem;
    }
  }
  .skeleton {
    margin-bottom: 1rem;
  }
`;
const AddUser = () => {
  const [customerData, setCustomerData] = useState([]);
  const [gender, setGender] = useState([]);
  const [dob, setDob] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [date, setDate] = useState("");
  const [latestDate, setLatestDate] = useState(null);
  const [orderData, setOrderData] = useState("No");
  const [showAddressCard, setShowAddressCard] = useState(false);
  const {
    addressCard,
    setAddressCard,
    setShowAddressSelectCheckout,
    showAddressSelectCheckout,
  } = useContext(store);
  const [mobile, setMobile] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addAddress, setaddAddress] = useState(false);
  const [logintime, setLogintime] = useState("");
  const [status, setStatus] = useState("");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    //
    var lastlogin = localStorage.getItem("lastlogin");
    var LastDateTime = JSON.parse(lastlogin);
    setLogintime(LastDateTime);
    // Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_customerData_by_id"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //
          setCustomerData(obj.data);
          setMobile(obj.data[0].fld_phone_number);
          setaddAddress(obj.data[0].fld_address_1);
          setGender(obj.data[0].fld_gender);
          setDob(obj.data[0].fld_dob);
          setCountry(obj.data[0].CountryName);
          setState(obj.data[0].StateName);
          setCity(obj.data[0].CityName);
          setPincode(obj.data[0].fld_pincode);
          setLandmark(obj.data[0].fld_landmark);
          setStatus(obj.data[0].fld_status);
          setLoading(false);
          Notiflix.Loading.remove();
        }
      })
    );
    // ===============Address Data=================
    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
      },
      "GetCustomerAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAddressData(obj.data);
          setLoading(false);
        }
      })
    );
    PostApiCall.postRequest(
      {
        WhereClause: ` where fld_customer_id=${CustomerDetails[0].fld_customerid} order by fld_order_date desc`,
        FromClause: " top 1 * ",
      },
      "GetCustomerDashBoardDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setOrderData(obj.data);
          setLoading(false);
          setLatestDate(
            obj.data.map((e) => {
              return e.fld_order_date;
            })
          );
        }
      })
    );
  }, [showAddressCard]);

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleClose = () => setShowAddressCard(false);
  const rowSkeletons = 3;
  if (loading) {
    let rows = [];
    for (let index = 0; index < rowSkeletons; index++) {
      rows.push(
        <section className="ms-4 mb-4">
          <Skeleton height={400} />
        </section>
      );
    }

    return (
      <>
        <SkeletonTheme color="#F5F5F5" highlightColor="#ffffff">
          <GalleryStyles className="gallery__grid">
            <Container>
              <Row className="mt-5">
                <Col lg={5}>
                  <Skeleton height={400} />
                </Col>
                <Col lg={3}>
                  <Skeleton height={400} />
                </Col>
                <Col lg={4}>
                  <Skeleton height={400} />
                </Col>
              </Row>
              <div className="gallery__grid">{rows}</div>
              <Row className="mt-lg-5">
                <Col className="mb-4 gap-1">
                  <Skeleton height={230} />
                </Col>
                <Col className="mb-4 gap-1">
                  <Skeleton height={230} />
                </Col>
                <Col className="mb-4 gap-1">
                  <Skeleton height={230} />
                </Col>
              </Row>
              <Row className="mt-lg-5">
                <Col className="mb-4 gap-1">
                  <Skeleton height={230} />
                </Col>
                <Col className="mb-4 gap-1">
                  <Skeleton height={230} />
                </Col>
                <Col className="mb-4 gap-1">
                  <Skeleton height={230} />
                </Col>
              </Row>
            </Container>
          </GalleryStyles>
        </SkeletonTheme>
      </>
    );
  }
  return (
    <Container>
      <div className="page-content">
        <Row>
          <Col xl="12">
            <Row style={{ marginTop: "15px" }}>
              <Col lg={5} className="my-4">
                <Card className="overflow-hidden viewcustomer-top-card-size">
                  <div className="customer-profile-bg-color">
                    {customerData.map((data, i) => {
                      if (i == 0) {
                        return (
                          <Row>
                            <Col xs="7">
                              <div className="customerProfile-text p-3">
                                <h5
                                  className="customerProfile-text mb-0"
                                  style={{ display: "flex" }}
                                >
                                  Hi,&nbsp;
                                  {data.fld_name}
                                </h5>
                              </div>
                            </Col>
                            <Col xs="5" className="align-self-end"></Col>
                          </Row>
                        );
                      }
                    })}
                  </div>
                  <Card.Body>
                    <Row>
                      <Col xs="5" className="customer-details">
                        <p className="mb-2">Date of Birth</p>
                        <p className="mb-2">Gender</p>
                        <p className="mb-2">Marital Status</p>
                        <p className="mb-2">Date of Anniversary</p>
                        <p className="mb-2">Mobile</p>
                        <p className="mb-2">Email</p>
                        <p className="mb-2">GSTIN</p>
                      </Col>
                      {customerData.map((data, i) => {
                        if (i == 0) {
                          return (
                            <Col xs="7" className="customer-details">
                              <p className="mb-2">
                                {data.fld_dob != null
                                  ? moment(data.fld_dob).format("DD/MM/YYYY")
                                  : "-"}{" "}
                              </p>
                              <p className="mb-2">
                                {data.fld_gender == null
                                  ? "-"
                                  : data.fld_gender}
                              </p>
                              <p className="mb-2">
                                {data.maritalStatus == null
                                  ? "-"
                                  : data.maritalStatus}
                              </p>
                              <p className="mb-2">
                                {data.fld_doa == null
                                  ? "-"
                                  : moment(data.fld_doa).format("DD/MM/YYYY")}
                              </p>
                              <p className="mb-2">{data.fld_phone_number}</p>
                              <p className="mb-2">{data.fld_email_address}</p>
                              <p className="mb-2">{data.fld_gstin}</p>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} className="my-4">
                <Card className="mini-stats-wid ">
                  <Card.Body className="viewcustomer-order-summary">
                    <Media>
                      <Media body>
                        {orderData.length == 0 ? (
                          <div className="row">
                            <div className="col-md-12">
                              <h5 className="mb-0 fw-bold">Order Summary</h5>
                            </div>
                            <div
                              className="col-lg-12 col-sm-12 col-md-12 justify-content-center mt-5"
                              style={{ textAlign: "center" }}
                            >
                              <img src={Searchicon} className="not-available" />
                              <h5 className="">No orders yet !</h5>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h5 className="mb-0 fw-bold">
                                    Order Summary
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="mt-3 view-customer-total-order">
                              <div className="col-12 p-2 borderview-customer">
                                <h6>
                                  <strong> Your last order date</strong>
                                </h6>
                                <h6>{date.fld_order_date}</h6>
                              </div>
                              <div className="col-12 p-2 borderview-customer">
                                <h6 className="mb-0">
                                  <strong> Total Orders</strong>
                                </h6>
                                <p className="viewcustomer-ordersummery-font mb-0 pb-0">
                                  {date.total_order}
                                </p>
                              </div>
                              <div className="col-12  p-2 borderview-customer">
                                <h6 className="mb-0">
                                  <strong>Total Purchase Value</strong>
                                </h6>
                                <p className="viewcustomer-ordersummery-font mb-0 pb-0">
                                  {date.OrderValue}
                                </p>
                              </div>
                              <div className="col-12  p-2 borderview-customer">
                                <h6 className="mb-0">
                                  <strong>You have Saved</strong>
                                </h6>
                                <p className="viewcustomer-ordersummery-font mb-0 pb-0">
                                  {date.total_discount}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </Media>
                    </Media>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="my-4">
                <OrderGraph />
              </Col>
            </Row>
            <Row>
              <Col xl="12 mb-4">
                <CustomerCardHistory />
              </Col>
            </Row>
            <Row>
              <Col xl="12 mb-4">
                <CustomerOrderList />
              </Col>
            </Row>
            <Row>
              <Col xl="12 mb-4">
                <Card className="overflow-hidden ">
                  <Card.Body>
                    <Card.Title className="usertitle">
                      <h4 className="mb-3">Address Book</h4>
                    </Card.Title>
                    {addressData.length != 0 ? (
                      <Row>
                        {addressData.map((data, i) => (
                          <Col xs="12" lg={4}>
                            <div className="categoryCard mb-3">
                              <Card className="p-3 addressCard-profile">
                                <div className="">
                                  <Card.Title className="d-flex customer-profile-address">
                                    <b>{data.fld_contact_name}</b>
                                    <span
                                      style={{
                                        position: "absolute",
                                        right: "30px",
                                      }}
                                    >
                                      <Badge className="badge">
                                        {data.fld_address_type}
                                      </Badge>{" "}
                                    </span>
                                  </Card.Title>
                                  <p>
                                    {data.fld_address_1},{data.fld_address_2},
                                    {data.CityName}-{data.fld_pincode},
                                  </p>
                                  <p>
                                    {data.StateName},{data.CountryName}
                                  </p>
                                  <p
                                    style={{
                                      display:
                                        data.fld_landmark == null
                                          ? "none"
                                          : "block",
                                    }}
                                  >
                                    <ImLocation2 /> {data.fld_landmark}
                                  </p>
                                  <p style={{ marginBottom: "0" }}>
                                    <FaMobileAlt />
                                    &nbsp; {data.fld_contact_number}
                                  </p>
                                  <div className="d-flex fs-5 adressCard-icons">
                                    <div
                                      onClick={() => {
                                        localStorage.setItem(
                                          "AddressData",
                                          JSON.stringify(data)
                                        );
                                        setShowAddressCard(true);
                                      }}
                                    >
                                      <MdEdit />
                                    </div>
                                    <div
                                      class="address-icon ms-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        Notiflix.Loading.dots("");
                                        var customer =
                                          localStorage.getItem("CustomerData");
                                        var CustomerDetails =
                                          JSON.parse(customer);
                                        PostApiCall.postRequest(
                                          {
                                            CUSTOMERID:
                                              CustomerDetails[0].fld_customerid,
                                            addressid: data.fld_address_id,
                                            title: data.fld_address_type,
                                            name: data.fld_contact_name,
                                            mobileNo: data.fld_contact_number,
                                            area: data.fld_address_2,
                                            flatno: data.fld_address_1,
                                            landmark: data.fld_landmark,
                                            country: data.Country,
                                            state: data.State,
                                            city: data.City,
                                            pincode: data.fld_pincode,
                                            action: "DELETE",
                                          },
                                          "AddCustomerAddress"
                                        ).then((results) =>
                                          results.json().then((obj) => {
                                            if (
                                              results.status == 200 ||
                                              results.status == 201
                                            ) {
                                              PostApiCall.postRequest(
                                                {
                                                  whereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
                                                },
                                                "GetCustomerAddress"
                                              ).then((results) =>
                                                results.json().then((obj) => {
                                                  if (
                                                    results.status == 200 ||
                                                    results.status == 201
                                                  ) {
                                                    setAddressData(obj.data);
                                                    setLoading(false);
                                                    Notiflix.Notify.success(
                                                      "Address Successfully Delete."
                                                    );
                                                    Notiflix.Loading.remove();
                                                  }
                                                })
                                              );
                                            }
                                          })
                                        );
                                      }}
                                    >
                                      <RiDeleteBin6Fill />
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </Col>
                        ))}
                        <Col lg={4}>
                          <Card
                            className="addressCard-profile"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowAddressCard(true);
                            }}
                          >
                            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                              <div className="fs-4">
                                <MdAddLocationAlt />
                              </div>
                              <div>Add address</div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="justify-content-center text-center">
                        <Col lg={3}>
                          <div className="text-center mt-3">
                            <h6 className="fw-bold">
                              There is no address available.
                            </h6>
                            <p className="small">
                              Add an address to proceed with ease.
                            </p>
                          </div>
                          <button
                            className="btn shop-btn"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowAddressCard(true);
                            }}
                          >
                            <div className="fs-4">
                              <MdAddLocationAlt />
                            </div>
                            <div>Add address</div>
                          </button>
                        </Col>
                      </Row>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Offcanvas
        className="address-offcanvas"
        show={showAddressCard}
        placement="end"
        onHide={
          // localStorage.removeItem("AddressData"),
          handleClose
        }
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {localStorage.getItem("AddressData")
              ? "Edit Your Address"
              : "Add New Address"}{" "}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddaddressForm closeCanvas={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};
export default AddUser;
