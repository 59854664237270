import React from "react";
import Noimage from "../../../assets/images/noimage/noimage.png";
import "./HomeProduct.css";

function HomeProduct({ productdata, featuredata }) {
  return (
    <section className="mt-md-5 mt-4">
      <div className="container-fluid container-md">
        <div className="row">
          <div className="col-6 text-center mt-0 px-md-2 ps-2 pe-1">
            <div className="card border-0">
              <div className="card-body p-0 m-0">
                <h2 className="text-center fw-bold product-h3-content">
                  Bestseller
                </h2>
                <h4 className="wow animate__animated animate__fadeInUp testimonial-h4-content d-sm-flex d-none text-center justify-content-center">
                  Highly Recommended
                </h4>
                {productdata?.slice(0, 1).map((data) => {
                  return (
                    <div className="homepro-name mt-3">
                      <img
                        src={data.VariantImage.split("#")[0]}
                        className="w-100 h-100 common-border-radious"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              className="shop-btn mt-3 px-md-5 px-3 py-2 mb-3 mb-md-0 home-product-shop-now"
              onClick={() => (window.location.href = "/shop")}
            >
              <p className="m-0 p-0 product-p-shopnow">Shop Now</p>
            </button>
          </div>

          <div className="col-6 text-center mt-0 mt-sm-0 px-md-2 pe-2 ps-1">
            <div className="card border-0">
              <div className="card-body p-0 m-0">
                <h2 className="text-center fw-bold product-h3-content">
                  Featured
                </h2>
                <h4 className="wow animate__animated animate__fadeInUp testimonial-h4-content d-sm-flex d-none text-center justify-content-center ">
                Bedding Highlights
                </h4>
                {featuredata?.slice(0, 1).map((data) => {
                  return (
                    <div className="homepro-name mt-3">
                      <img
                        src={data.VariantImage.split("#")[0]}
                        className="w-100 h-100 common-border-radious"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              className="shop-btn mt-3 px-md-5 px-3 py-2 mb-3 mb-md-0 home-product-shop-now"
              onClick={() => (window.location.href = "/shop")}
            >
              <p className="m-0 p-0 product-p-shopnow">Shop Now</p>
            </button>
          </div>

          <div className="col-12 mt-md-3 mt-1 mb-0 pt-md-3 pt-0 text-center">
            <p className="mb-0 product-p-content">
              All our bedlinen is packed in our signature reusable cotton cloth
              bags.
            </p>
            <p className="my-0 product-p-content">
              Our exclusive hand block designs, are manufactured in limited
              quantities, that feature intricate motifs and patterns. Fine
              quality fabrics and the adept handiwork of our master artisans add
              a touch of luxury and sophistication to each product , especially
              made for beautiful homes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeProduct;
