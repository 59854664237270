import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Offcanvas, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PostApiCall from "../Api/postApi";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import { store } from "../context/store";
// skeleton
import { BiSearch, BiSolidChevronDownCircle } from "react-icons/bi";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import Card1 from "../components/ProductCards/Card1";
import _ from "lodash";
import GetApiCall from "../Api/getApi";

const GalleryStyles = styled.div`
  .gallery__grid {
    display: flex;
    gap: 0rem;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    justify-content: center;
    padding: 0 3% 0 3%;
  }
  .gallery__title {
    font-size: 2rem;
    padding: 3%;
    text-align: center;
  }
  .item {
    margin: auto;
    border: 3px solid var(--gray-1);
  }
  .item__btns {
    display: flex;
    justify-content: space-between;
    button {
      font-size: 1.125rem;
      background-color: var(--gray-1);
      padding: 0.2rem 0.5rem;
      height: 3rem;
      border-radius: 8px;
      font-weight: bolder;
    }
  }
  .item-img {
    width: 304px;
    height: 140px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      object-fit: contain;
    }
  }
  .item-title {
    font-size: 1rem;
    height: 82px;
    text-align: center;
    margin-bottom: 1rem;
  }
  .item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .item-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    width: 60px;
  }
  .item__btnadd {
    border: 2px solid var(--red-1);
    color: var(--red-1);
  }
  .item-price {
    font-size: 2.5rem;
    color: var(--blue-1);
    text-align: center;
    margin-bottom: 1rem;
  }
  .item__btnbuy {
    border: 2px solid var(--red-1);
    background-color: var(--red-1) !important;
    color: var(--gray-1);
  }
  .item-start {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid yellow;
    svg {
      font-size: 1rem;
    }
  }
  .skeleton {
    margin-bottom: 1rem;
  }
`;

const Productlist = ({ location }) => {
  const { newArrivals, setNewArrivals, ProductCart } = useContext(store);
  const [allNewArrivals, setAllNewArrivals] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // stores user search input
  const [loading, setLoading] = useState(true);
  const [filterType] = useState([
    // new added start
    { label: "All", value: "All" },
    { label: "Bestsellers", value: "Bestsellers" },
    { label: "Featured", value: "Featured" },
    // new added end
    { label: "Alphabetically, A to Z", value: "Alphabetically, A to Z" },
    { label: "Price, High to Low", value: "Price, High to Low" },
    { label: "Price, Low to High", value: "Price, Low to High" },
    // new added start
    { label: "Date: New to Old", value: "Date: New to Old" },
    { label: "Date: Old to New", value: "Date: Old to New" },
    // new added end
    { label: "Alphabetically, Z to A", value: "Alphabetically, Z to A" },
  ]);
  const [sortButton, setSortButton] = useState([]);
  const [filter, setFilter] = useState([]);
  const [bestSellerData, setBestSellerData] = useState([]);
  const [featuredata, setFeaturedData] = useState([]);
  const { reloadSubCategory, setReloadSubCategory } = useContext(store);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const params = useParams();
  var login = localStorage.getItem("CustomerData");
  var logindetails = JSON.parse(login);
  var orderid = 0;
  useEffect(() => {
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    var orderid = 0;
    if (localStorage.getItem("OrderDetails") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    GetApiCall.getRequest("GetHomepageSetup").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        if (
          homepagesetupresult.status === 200 ||
          homepagesetupresult.status === 201
        ) {
          localStorage.setItem("homepagesetupresult", JSON.stringify(obj.data));
          obj.data
            .filter((value) => value.fld_section_name == "Featured Products")
            .map((filtereddata) => {
              PostApiCall.postRequest(
                {
                  customerid:
                    localStorage.getItem("CustomerData") == null
                      ? null
                      : logindetails[0].fld_customerid,
                  categoryid: null,
                  productid: null,
                  variantid: null,
                  sectionname: "Featured Products",
                  orderid: orderid,
                  itemchoice: filtereddata.fld_item_choice,
                  // pincode: 110004,
                },
                "GetWebsiteHomePageItems"
              ).then((results) => {
                results.json()?.then((obj) => {
                  if (results.status == 200 || results.status == 201) {
                    // ;
                    setFeaturedData(obj.data);
                  }
                });
              });
            });

          obj.data
            .filter((value) => value.fld_section_name == "Best Seller")
            .map((filtereddata) => {
              PostApiCall.postRequest(
                {
                  customerid:
                    localStorage.getItem("CustomerData") == null
                      ? null
                      : logindetails[0].fld_customerid,
                  categoryid: null,
                  productid: null,
                  variantid: null,
                  sectionname: "Best Seller",
                  orderid: orderid,
                  itemchoice: filtereddata.fld_item_choice,
                  // pincode: 110004
                },
                "GetWebsiteHomePageItems"
              ).then((results) => {
                results.json()?.then((obj) => {
                  if (results.status == 200 || results.status == 201) {
                    setBestSellerData(obj.data);
                  }
                });
              });
            });
        }
      });
    });
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getSubCategory();
  }, [reloadSubCategory]);
  const getSubCategory = async () => {
    let processCategoryObj = {};
    if (window.location.pathname == "/shop") {
      processCategoryObj = {};
    } else {
      processCategoryObj = {
        categoryid: params.categoryid,
      };
    }
    PostApiCall.postRequest(processCategoryObj, "Get_All_Items").then(
      (results) => {
        results.json()?.then((obj) => {
          if (results.status == 200 || results.status == 201) {
            const activeItems = obj.data.filter(
              (item) => item.fld_status === "Active"
            );

            setNewArrivals(activeItems);
            setAllNewArrivals(activeItems);
            setLoading(false);
          }
        });
      }
    );
  };

  const rowSkeletons = 3;
  if (loading) {
    let rows = [];
    for (let index = 0; index < rowSkeletons; index++) {
      rows.push(
        <Container className="ms-5 mb-4">
          <Skeleton height={200} width={300} />
        </Container>
      );
    }

    return (
      <>
        <SkeletonTheme color="#F5F5F5" highlightColor="#ffffff">
          <GalleryStyles className="gallery__grid">
            <Skeleton height={200} />
            <div className="gallery__grid mt-4">{rows}</div>
          </GalleryStyles>
        </SkeletonTheme>
      </>
    );
  }

  const filteredData = newArrivals.filter((data) => {
    const lowerCaseSearchInput = searchInput.toLowerCase();

    const variantName = String(data.fld_variantname).toLowerCase();
    const discountPrice = String(data.fld_website_discount_price).toLowerCase();

    if (lowerCaseSearchInput === "") {
      return true;
    }

    return (
      variantName.includes(lowerCaseSearchInput) ||
      discountPrice.includes(lowerCaseSearchInput)
    );
  });

  // New filter type
  const getAllProducts = () => {
    setNewArrivals(allNewArrivals);
  };
  const SortByNewToOld = () => {
    let sortedProductsDsc;
    sortedProductsDsc = newArrivals.sort((a, b) => {
      return new Date(b.fld_createdon) - new Date(a.fld_createdon);
    });
    setNewArrivals([...sortedProductsDsc]);
  };
  const SortByOldToNew = () => {
    let sortedProductsAsc;
    sortedProductsAsc = newArrivals.sort((a, b) => {
      return new Date(a.fld_createdon) - new Date(b.fld_createdon);
    });
    setNewArrivals([...sortedProductsAsc]);
  };

  const SortByPriceHighToLow = () => {
    let sortedProductsDsc;
    sortedProductsDsc = newArrivals.sort((a, b) => {
      return (
        parseInt(b.fld_website_discount_price) -
        parseInt(a.fld_website_discount_price)
      );
    });
    setNewArrivals(sortedProductsDsc);
  };
  const SortByPriceLowToHigh = () => {
    let sortedProductsDsc;
    sortedProductsDsc = newArrivals.sort((a, b) => {
      return (
        parseInt(a.fld_website_discount_price) -
        parseInt(b.fld_website_discount_price)
      );
    });
    setNewArrivals(sortedProductsDsc);
  };
  const SortByAlphabeticallyAtoZ = () => {
    setNewArrivals(
      newArrivals.sort((a, b) =>
        a.fld_variantname > b.fld_variantname ? 1 : -1
      )
    );
  };
  const SortByAlphabeticallyZtoA = () => {
    setNewArrivals(
      newArrivals.sort((a, b) =>
        b.fld_variantname > a.fld_variantname ? 1 : -1
      )
    );
  };
  const SortByBestSeller = () => {
    setNewArrivals(bestSellerData);
  };
  const SortbyFeatured = () => {
    setNewArrivals(featuredata);
  };
  return (
    <>
      <section>
        <HeroBanner />
        <div className="container-fluid container-md">
          <div class="align-items-center justify-content-center mb-5 row">
            <div class="text-center col-lg-12">
              <div class="shadow filter-card card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center filter-bar card-text gap-lg-5">
                    <div class="filter-bar row w-100">
                      <div className="col-5 col-md-2 col-lg-4 col-xl-3 order-2 order-md-1 d-flex mt-3 mt-md-0">
                        <span className="sorting-option me-2 align-items-center d-none d-lg-flex">
                          Sort by
                        </span>
                        <span
                          className="sorting-option me-2 d-flex align-items-center d-block d-lg-none pe-sm-3"
                          onClick={handleShow}
                        >
                          Sort by <BiSolidChevronDownCircle className=" ms-2" />
                        </span>
                        <span className="d-none d-lg-block">
                          <Form.Select
                            aria-label="Featured"
                            value={filter}
                            onChange={(text) => {
                              setFilter(text.target.value);
                              switch (text.target.value) {
                                case "All":
                                  getAllProducts();
                                  break;
                                case "Featured":
                                  SortbyFeatured();
                                  break;
                                case "Bestsellers":
                                  SortByBestSeller();
                                  break;
                                case "Date: New to Old":
                                  SortByNewToOld();
                                  break;
                                case "Date: Old to New":
                                  SortByOldToNew();
                                  break;
                                case "Price, High to Low":
                                  SortByPriceHighToLow();
                                  break;
                                case "Price, Low to High":
                                  SortByPriceLowToHigh();
                                  break;
                                case "Alphabetically, A to Z":
                                  SortByAlphabeticallyAtoZ();
                                  break;
                                case "Alphabetically, Z to A":
                                  SortByAlphabeticallyZtoA();
                                  break;
                                default:
                                  break;
                              }
                            }}
                          >
                            {filterType.map((data) => (
                              <option
                                className="py-5"
                                key={data.value}
                                value={data.value}
                              >
                                {data.label}
                              </option>
                            ))}
                          </Form.Select>
                        </span>
                      </div>
                      <div className="position-relative col-md-7 col-lg-5 col-xl-7 order-1 order-md-2">
                        <input
                          type="text"
                          className="form-control ps-5"
                          value={searchInput}
                          onChange={(e) => {
                            setSearchInput(e.target.value);
                          }}
                          placeholder="Search for bedsheets, bedspreads and cushion covers "
                        ></input>
                        <BiSearch className="search-icon" />
                      </div>
                      <div class="total-products justify-content-start justify-content-lg-center justify-content-end mt-3 mt-md-0 col-7 col-md-3 col-xl-2 order-3 order-md-3">
                        <span>&nbsp;Total Products -&nbsp;</span>
                        <span> {filteredData.length} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {filteredData.length > 0 ? (
            <Row>
              {filteredData.map((productdata) => {
                const isInCart = ProductCart.find((item) => {
                  if (productdata.fld_variantid === item.fld_variantid) {
                    return item;
                  }
                  return false;
                });
                return (
                  <Col xl={4} lg={6} sm={6} xs={6} className="mb-4">
                    <Card1
                      product={productdata}
                      isInCart={isInCart}
                      showprice="true"
                      showshortdescription="true"
                      showitemname="true"
                      showaddrtocart="true"
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <>
              <Row className="justify-content-center align-items-center mb-5">
                <Col lg={12} className="text-center">
                  <h4>We couldn't find any matches!</h4>
                </Col>
              </Row>
            </>
          )}
        </div>
      </section>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        key="bottom-1"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="sorting-offcanvas">
            SORT BY
          </Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0 py-0" />
        <Offcanvas.Body>
          {filterType.map((data) => (
            <div
              className="d-flex my-2 justify-content-between fw-bold"
              value={sortButton}
              onClick={(e) => {
                setSortButton(data.value);
                setFilter(data.value);
                switch (data.value) {
                  case "All":
                    getAllProducts();
                    break;
                  case "Featured":
                    SortbyFeatured();
                    break;
                  case "Bestsellers":
                    SortByBestSeller();
                    break;
                  case "Date: New to Old":
                    SortByNewToOld();
                    break;
                  case "Date: Old to New":
                    SortByOldToNew();
                    break;
                  case "Price, High to Low":
                    SortByPriceHighToLow();
                    break;
                  case "Price, Low to High":
                    SortByPriceLowToHigh();
                    break;
                  case "Alphabetically, A to Z":
                    SortByAlphabeticallyAtoZ();
                    break;
                  case "Alphabetically, Z to A":
                    SortByAlphabeticallyZtoA();
                    break;
                  default:
                    break;
                }
                handleClose();
              }}
            >
              <div key={data.value} value={data.value}>
                {data.label}
              </div>
              <Form.Check
                className="sorting-radio-button"
                name="group1"
                type="radio"
                // checked={sortButton =}
                id={`reverse-radio-2`}
              />
            </div>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default Productlist;
