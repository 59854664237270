import { AES } from "crypto-js";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToWords } from "to-words";
import PostApiCall from "../../Api/postApi";
import Payment_Success from "../../assets/images/Payment_successfull.png";
import { store } from "../../context/store";

const PaymentSuccess = () => {
    let params = useParams();
    let navigate = useNavigate();
    let encryptdOrderId = [];
    const [mainOrder, setMainOrder] = useState([])
    const { Cart, setCart } = useContext(store);
    const [prodData, setProdData] = useState([])
    const { ProductCart, setProductCart } = useContext(store);
    const [amountInWords, setAmountInWords] = useState([])
    useEffect(() => {
        Notiflix.Notify.init({
            width: "300px",
            position: "center-bottom",
            distance: "10px",
            opacity: 1,
        });

        Notiflix.Loading.dots("");

        PostApiCall.postRequest(
            {
                whereClause: `where  fld_payment_trx_num='${params.txnid}'`,
            },
            "GetWebsiteOrderDetails"
        ).then((results1) =>
            // const objs = JSON.parse(result._bodyText)
            results1.json().then((obj) => {
                if (results1.status == 200 || results1.status == 201) {
                    //
                    Notiflix.Loading.remove();
                    setMainOrder(obj.data);
                    setProdData(obj.data);
                    mainOrder.map((data, i) => {
                        if (i === 0) {
                            const toWords = new ToWords();
                            let words = toWords.convert(data.Total_Inclusive_Tax);
                            setAmountInWords(words)
                        }
                    });
                }
            })
        );
        localStorage.removeItem("OrderDetails");
        SendMailers();
    }, [])
    const GetCartdetails = () => {
        var Order = localStorage.getItem("OrderDetails");
        var Orderdetails = JSON.parse(Order);
        var orderid = 0;
        if (localStorage.getItem("OrderDetails") !== null) {
            orderid = Orderdetails[0].order_id
        }

        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        if (localStorage.getItem("OrderDetails") !== null) {
            var Order = localStorage.getItem("OrderDetails");
            var Orderdetails = JSON.parse(Order);
            PostApiCall.postRequest(
                {
                    orderid:
                        localStorage.getItem("OrderDetails") == null
                            ? null
                            : Orderdetails[0].order_id,
                    id:
                        localStorage.getItem("CustomerData") == null
                            ? null
                            : logindetails[0].fld_customerid,
                },
                "GetCartWebsite"
            )
                .then((res) => res.json())
                .then((res1) => {
                    if (res1.data) {
                        setProductCart(res1.data);
                        setCart((res1.data.reduce((a, v) => a = a + v.fld_quantity, 0)))
                    }
                });
        }


    }
    const SendMailers = () => {
        Notiflix.Loading.dots();
        var Order = localStorage.getItem("OrderDetails");
        var login = localStorage.getItem("CustomerData");
        var Orderdetails = JSON.parse(Order);
        var logindetails = JSON.parse(login);
        var orderid = 0;
        if (localStorage.getItem("OrderDetails") !== null) {
            orderid = Orderdetails[0].order_id
        }
        PostApiCall.postRequest(
            {
                ordernumber: params.txnid,
                customername: logindetails[0].fld_name

            },
            "CustomerOrderSuccessMailer"
        ).then((results2) =>
            // const objs = JSON.parse(result._bodyText)
            results2.json().then((obj2) => {
                if (results2.status == 200 || results2.status == 201) {
                    PostApiCall.postRequest(
                        {
                            cartID:
                                orderid
                        },
                        "PurgeCart"
                    ).then((result) =>
                        result
                            .json()
                            .then((obj3) => {
                                if (
                                    result.status ==
                                    200 ||
                                    result.status == 201
                                ) {
                                    GetCartdetails();
                                    GetCartdetails();
                                } else {
                                    Notiflix.Notify.failure(
                                        "Not able to process data"
                                    );
                                }
                            })
                    );
                    // window.location.href = `/paymentprocess/${mainOrders.fld_payment_trx_num}`;
                }
            })
        );
    }


    const id = params.txnid;
    const secretKey = 'mel#4321!';
    const encryptedID = AES.encrypt(JSON.stringify(id.toString()), secretKey).toString();
    const urlSafeBase64 = encryptedID.replace(/\//g, "-").toString().replace("?", "bechofy")
    return (
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div className="mt-3 text-end">
                            <button
                                className="btn btn-theme change-addressButton"
                                onClick={() => {
                                    navigate(`/view-invoice-form/${urlSafeBase64}`)
                                }}
                            >
                                view order
                            </button>
                            <button
                                // className="btn btn-theme change-addressButton mx-2"
                                className="btn shop-btn change-addressButton mx-2"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                Continue Shopping
                            </button>
                        </div>
                        <div className="d-flex justify-content-center">
                            <img src={Payment_Success} alt="payment success" className="w-50"></img>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}
export default PaymentSuccess;
