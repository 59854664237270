import Notiflix from "notiflix";
import React, { useContext } from "react";
import "react-multi-carousel/lib/styles.css";
import PostApiCall from '../../Api/postApi';
import { store } from "../../context/store";
import "./OrderSummary1.css";
import Carousel from "react-multi-carousel";


function OrderSummary1(props) {
    const { ProductCart } = useContext(store);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    <div className='orderdetailssummery'>
        <Carousel responsive={responsive}>
            {ProductCart.map((data, index) => {
                return (

                    <div className='productinfolist'>
                        <div className="product-thumbnail ">
                            <div className="product-thumbnail__wrapper">
                                <img
                                    alt="Skylofts Rabbit LED Night Lamp Plug"
                                    className="product-thumbnail__image1 checkout_page_product_img1"
                                    src={data.VariantImage}
                                />
                            </div>
                            <span
                                className="product-thumbnail__quantity"
                                aria-hidden="true"
                            >
                                {data.fld_quantity} items
                            </span>
                            <span
                                className="product-thumbnail__quantity1"
                                aria-hidden="true"
                            >
                                <cart-remove-button
                                    id="Remove-1"
                                    data-index="1"
                                >
                                    <a
                                        className="deletebutton"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            var login =
                                                localStorage.getItem("CustomerData");
                                            var logindetails = JSON.parse(login);
                                            PostApiCall.postRequest(
                                                {
                                                    cartID: data.fld_cart_id,
                                                    customerid:
                                                        localStorage.getItem(
                                                            "CustomerData"
                                                        ) == null
                                                            ? null
                                                            : logindetails[0]
                                                                .fld_customerid,
                                                    cartlineid: data.fld_cart_dtl_id,
                                                    stateid: 0,
                                                },
                                                "DeleteCart"
                                            ).then((result) =>
                                                result.json().then((obj3) => {
                                                    if (
                                                        result.status == 200 ||
                                                        result.status == 201
                                                    ) {
                                                        Notiflix.Notify.success(
                                                            "Product is successfully deleted."
                                                        );
                                                        // this.getCartdetails();
                                                        if (
                                                            ProductCart.length ==
                                                            0
                                                        ) {
                                                            localStorage.removeItem(
                                                                "OrderDetails"
                                                            );
                                                        }
                                                        // this.getCartdetails();
                                                    } else {
                                                        Notiflix.Notify.failure(
                                                            obj3.data
                                                        );
                                                    }
                                                })
                                            );
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            aria-hidden="true"
                                            focusable="false"
                                            role="presentation"
                                            className="icon icon-remove"
                                            style={{
                                                height: "20px",
                                                width: "20px",
                                            }}
                                        >
                                            <path
                                                d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z"
                                                fill="currentColor"
                                            ></path>
                                            <path
                                                d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </a>
                                </cart-remove-button>
                            </span>
                        </div>
                        <div className='itemdetailsname'>
                            <p className="product__description__name order-summary__emphasis checkoutshowitemfontphn ditails-font">
                                {data.fld_variantname}
                            </p>
                            {
                                data.fld_mrp == data.fld_website_discount_price
                                    ?
                                    <p className="product__price ditails-font" >
                                        <span className="order-summary__emphasis skeleton-while-loading checkoutshowitemfontphn" >
                                            ₹
                                            {parseFloat(
                                                data.fld_website_discount_price
                                            ).toFixed(2)}
                                        </span>
                                    </p>
                                    :
                                    <p className="product__price ditails-font" >
                                        {/* checkoutshowitemfontphn add by laxmi-----------------for phn viewsize */}
                                        <del className="order-summary__small-text skeleton-while-loading--sm checkoutshowitemfontphn" >
                                            ₹   {parseFloat(data.fld_mrp).toFixed(2)}
                                        </del>
                                        <br />
                                        {/* checkoutshowitemfontphn add by laxmi-----------------for phn viewsize */}
                                        <span className="order-summary__emphasis skeleton-while-loading checkoutshowitemfontphn">
                                            ₹
                                            {parseFloat(
                                                data.fld_website_discount_price
                                            ).toFixed(2)}
                                        </span>
                                    </p>
                            }

                        </div>
                    </div>
                );
            })}
        </Carousel>
    </div>
}
export default OrderSummary1;