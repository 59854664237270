import Notiflix from "notiflix";
import React, { useContext } from "react";
import {
    Col, Row
} from "react-bootstrap";
import { TiDelete } from "react-icons/ti";
import "react-multi-carousel/lib/styles.css";
import PostApiCall from '../../Api/postApi';
import { store } from "../../context/store";
import "./BillDetailsCheckout1.css";
function BillDetailsCheckout1(props) {
    const { ProductCart } = useContext(store);
    const SubTotal = props.SubTotal;
    const CouponDiscountAmount = props.CouponDiscountAmount;
    const CouponCode = props.CouponCode
    const OfferDiscountAmount = props.OfferDiscountAmount;
    const CouponId = props.CouponId;
    const GST = props.GST;
    const ShippingCharges = props.ShippingCharges;
    const GSTonShipping = props.GSTonShipping;
    const GrandTotal = props.GrandTotal;
    const CartId = props.CartId;
    function removeCoupon(offerid) {
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        if (offerid !== null) {
            PostApiCall.postRequest(
                {
                    orderid: CartId,
                    couponid: offerid,
                    customerid:
                        localStorage.getItem("CustomerData") == null
                            ? null
                            : logindetails[0].fld_customerid,
                    status: "remove",
                },
                "ApplyOffer"
            ).then((result) =>
                result.json().then((obj3) => {
                    if (result.status == 200 || result.status == 201) {
                        Notiflix.Notify.success("Coupon removed successfully");
                        props.handleCoupon();
                        // this.getCartwebsite();
                    }
                })
            );
        } else {
        }
    }
    return (
        <div className="oderDetails p-3 mt-4 py-2">
            <Row className="line-height">
                <div className="border-bottom">
                    <span className="heading-font">Bill Details</span>
                </div>
                <Col lg={8}>Total</Col>
                <Col lg={4} className="text-end">
                    ₹{" "}
                    {ProductCart.length == 0
                        ? 0
                        : parseFloat(SubTotal).toFixed(2)}
                </Col>
                <Col
                    lg={7}
                    style={{
                        display:
                            typeof OfferDiscountAmount == "number" &&
                                OfferDiscountAmount > 0
                                ? "block"
                                : "none",
                    }}
                >
                    Discount on MRP
                </Col>
                <Col
                    lg={5}
                    style={{
                        display:
                            typeof OfferDiscountAmount == "number" &&
                                OfferDiscountAmount > 0
                                ? "block"
                                : "none",
                    }}
                    className="text-end"
                >
                    ₹{" "}
                    {ProductCart.length == 0
                        ? 0
                        : parseFloat(OfferDiscountAmount).toFixed(2)}
                </Col>
                <Col
                    lg={7}
                    style={{
                        display:
                            typeof CouponDiscountAmount == "number" &&
                                CouponDiscountAmount > 0
                                ? "block"
                                : "none",
                    }}
                >
                    Coupon Discount ({CouponCode})
                    <span
                        onClick={() => {
                            removeCoupon(CouponId);
                            
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <TiDelete fontSize={24} />
                    </span>
                </Col>
                <Col
                    lg={5}
                    style={{
                        display:
                            typeof CouponDiscountAmount == "number" &&
                                CouponDiscountAmount > 0
                                ? "block"
                                : "none",
                    }}
                    className="text-end"
                >
                    {" "}
                    ₹{" "}
                    {ProductCart.length == 0
                        ? 0
                        : parseFloat(CouponDiscountAmount).toFixed(2)}
                </Col>
                <Col
                    lg={7}
                    style={{
                        display:
                            typeof GST === "number" && GST > 0
                                ? "block"
                                : "none",
                    }}
                >
                    GST
                </Col>
                <Col
                    lg={5}
                    style={{
                        display:
                            typeof GST === "number" && GST > 0
                                ? "block"
                                : "none",
                    }}
                    className="text-end"
                >
                    ₹{" "}
                    {ProductCart.length == 0
                        ? 0
                        : parseFloat(GST).toFixed(2)}
                </Col>
                <Col
                    lg={7}
                    style={{
                        display:
                            typeof ShippingCharges == "number" &&
                                ShippingCharges > 0
                                ? "block"
                                : "none",
                    }}
                >
                    Shipping Charge
                </Col>
                <Col
                    lg={5}
                    style={{
                        display:
                            typeof ShippingCharges == "number" &&
                                ShippingCharges > 0
                                ? "block"
                                : "none",
                    }}
                    className="text-end"
                >
                    ₹{" "}
                    {ProductCart.length == 0
                        ? 0
                        : parseFloat(ShippingCharges).toFixed(2)}
                </Col>
                <Col
                    lg={7}
                    style={{
                        display:
                            typeof GSTonShipping == "number" &&
                                GSTonShipping > 0
                                ? "block"
                                : "none",
                    }}
                >
                    GST on Shipping
                </Col>
                <Col
                    lg={5}
                    style={{
                        display:
                            typeof GSTonShipping == "number" &&
                                GSTonShipping > 0
                                ? "block"
                                : "none",
                    }}
                    className="text-end"
                >
                    ₹{" "}
                    {ProductCart.length == 0
                        ? 0
                        : parseFloat(GSTonShipping).toFixed(2)}
                </Col>
                <hr className="mb-0" />
                <Col
                    lg={7}
                    style={{
                        display:
                            typeof GrandTotal == "number"
                                ? "block"
                                : "none",
                    }}
                    className="heading-font"
                >
                    Amount Payable
                </Col>
                <Col
                    lg={5}
                    style={{
                        display:
                            typeof GrandTotal == "number"
                                ? "block"
                                : "none",
                    }}
                    className="text-end heading-font"
                >
                    ₹{" "}
                    {ProductCart.length == 0
                        ? 0
                        : parseFloat(GrandTotal).toFixed(2)}
                </Col>

                <Col
                    lg={12}
                    style={{
                        display:
                            typeof (
                                CouponDiscountAmount +
                                OfferDiscountAmount
                            ) == "number" && CouponDiscountAmount > 0
                                ? "block"
                                : "none",
                    }}
                    className="orderDetailsBottom text-center"
                >
                    Your total savings amount on this order is ₹{" "}
                    {ProductCart.length == 0
                        ? 0
                        : (
                            parseFloat(CouponDiscountAmount) +
                            parseFloat(OfferDiscountAmount)
                        ).toFixed(2)}
                </Col>
            </Row>
        </div>
    )
}
export default BillDetailsCheckout1