import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import GetApiCall from "../Api/getApi";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import { Skeleton } from "antd";

function Aboutpage() {
  const [aboutUs, setAboutUs] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetApiCall.getRequest("GetAboutUs")
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          setAboutUs(res1.data[0]);
        }
      });
  }, []);
  return (
    <>
      <section>
        <HeroBanner />

        <div className="container-fluid container-md">
          {console.log(aboutUs)}
          {aboutUs.length <= 0 ? (
            <Row className="justify-content-center">

              <Col lg={10} className="text-center">
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3 w-25"
                  block
                  size="small"
                />
              </Col>
              <Col lg={10}>
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="small"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="small"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="small"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="small"
                />
              </Col>
              <Col lg={10} className="mb-4">
                <Skeleton.Image
                  active={true}
                  className="img-fluid w-100"
                />

              </Col>
              <Col lg={10}>

                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="small"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="small"
                />
                <Skeleton.Input
                  active={true}
                  className="pb-4 mb-3"
                  block
                  size="small"
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="justify-content-center about-inner-page">
                <Col lg={10} className="text-center">
                  {/* <p className="main-text">{aboutUs.fld_main_text}</p>  */}
                  <h2 className="testimonial-h2-content">{aboutUs.fld_main_title}</h2>
                  <h4 className="wow animate__animated animate__fadeInUp testimonial-h4-content">
                    Empowering Your Vision Together
                  </h4>
                </Col>
              </Row>
              <Row className="pt-lg-3 pt-3 justify-content-center align-items-center about-inner-page">
                <Col lg={10}>
                  <p
                    className="text-justify"
                    dangerouslySetInnerHTML={{
                      __html: aboutUs.fld_main_text1,
                    }}></p>
                  <p
                    className="text-justify"
                    dangerouslySetInnerHTML={{
                      __html: aboutUs.fld_main_text2,
                    }}></p>
                </Col>
              </Row>
              <Row className="pb-lg-5 pb-5 justify-content-center align-items-center about-inner-page">
                <Col lg={10}>
                  <p
                    className="text-justify"
                    dangerouslySetInnerHTML={{
                      __html: aboutUs.fld_main_text3,
                    }}></p>
                  <p
                    className="text-justify"
                    dangerouslySetInnerHTML={{
                      __html: aboutUs.fld_main_text4,
                    }}></p>
                </Col>
              </Row>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default Aboutpage;
