import { useEffect, useState } from "react";
import GetApiCall from "../../../Api/getApi";
import "./Banner.css";
import { Skeleton } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BannerImg1 from "../../../assets/images/Banner/NewBanner/Floral Buds Full Shot home top banner-Photoroom.jpg";
import BannerImg2 from "../../../assets/images/Banner/NewBanner/Floral Weave Home Top Banner.jpg";
import BannerImg3 from "../../../assets/images/Banner/NewBanner/Marigold Yellow Home Top Banner.jpg";
import BannerImg4 from "../../../assets/images/Banner/NewBanner/Marigold green Home Top Banner.jpg";
import BannerImg5 from "../../../assets/images/Banner/NewBanner/Morning Aster Home Top Banner.jpg";
import BannerImg6 from "../../../assets/images/Banner/NewBanner/Paisley Home Top Banner.jpg";
import BannerImg7 from "../../../assets/images/Banner/NewBanner/Waterslide Home Top Banner.jpg";

// Phone view banner
import BannerPhoneImg1 from "../../../assets/images/Banner/PhoneBanner/Floral Buds Full Shot 1080 x1080.jpg";
import BannerPhoneImg2 from "../../../assets/images/Banner/PhoneBanner/Floral Weave Full Shot 1080x1080.jpg";
import BannerPhoneImg3 from "../../../assets/images/Banner/PhoneBanner/Marigold green full shot 1080x1080.jpg";
import BannerPhoneImg4 from "../../../assets/images/Banner/PhoneBanner/Morning Aster Full shot 1080x1080.jpg";
import BannerPhoneImg5 from "../../../assets/images/Banner/PhoneBanner/Paisley Full shot 1080x1080.jpg";

function MainSlider() {
  const settings = {
    accessibility: false,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [bannerData, setBannerData] = useState([]);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const [index, setIndex] = useState(0);
  useEffect(() => {
    GetApiCall.getRequest("GetBanners").then((results) => {
      results.json()?.then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setBannerData(obj.data);
        }
      });
    });
  }, []);

  return (
    <section>
      <div className="container-fluid main-banner p-0 m-0">
        {bannerData.length <= 0 ? (
          <div className="w-100">
            <Skeleton.Image active={true} className="w-100" />
          </div>
        ) : (
          <>
            <Slider {...settings} className="banner-slider-container">
              <div className="banner-img position-relative">
                <img
                  src={BannerImg1}
                  className="w-100 h-100 d-none d-md-flex"
                />
                <img
                  src={BannerPhoneImg1}
                  className="w-100 h-100 d-flex d-md-none"
                />
                <div className="overlayHomeBanner d-none d-md-flex"></div>
                <div className="banner-content position-absolute">
                  <h1 className="fw-bold">New Arrivals</h1>
                  <h6 className="banner-h6-content">
                    A higher GSM indicates a higher density of fibers in the
                    fabric, resulting in a softer and more durable sheet.
                    Generally, GSM values for bed sheets range from 75 to 120
                    and can reach up to 170. Thread count, on the other hand,
                    refers to the number of threads woven into one square inch
                    of fabric.
                  </h6>
                  {/* <button className="banner-shop-btn mt-3 px-5 py-2">
                    <p className="m-0 p-0 banner-h6-content">Shop Now</p>
                  </button> */}
                </div>
              </div>
              <div className="banner-img position-relative">
                <img
                  src={BannerImg2}
                  className="w-100 h-100 d-none d-md-flex"
                />
                <img
                  src={BannerPhoneImg2}
                  className="w-100 h-100 d-flex d-md-none"
                />
                <div className="overlayHomeBanner d-none d-md-flex"></div>
                <div className="banner-content position-absolute">
                  <h1 className="fw-bold">New Arrivals</h1>
                  <h6 className="banner-h6-content">
                    A higher GSM indicates a higher density of fibers in the
                    fabric, resulting in a softer and more durable sheet.
                    Generally, GSM values for bed sheets range from 75 to 120
                    and can reach up to 170. Thread count, on the other hand,
                    refers to the number of threads woven into one square inch
                    of fabric.
                  </h6>
                </div>
              </div>
              <div className="banner-img position-relative">
                <img
                  src={BannerImg3}
                  className="w-100 h-100 d-none d-md-flex"
                />
                <img
                  src={BannerPhoneImg3}
                  className="w-100 h-100 d-flex d-md-none"
                />
                <div className="overlayHomeBanner d-none d-md-flex"></div>
                <div className="banner-content position-absolute">
                  <h1 className="fw-bold">New Arrivals</h1>
                  <h6 className="banner-h6-content">
                    A higher GSM indicates a higher density of fibers in the
                    fabric, resulting in a softer and more durable sheet.
                    Generally, GSM values for bed sheets range from 75 to 120
                    and can reach up to 170. Thread count, on the other hand,
                    refers to the number of threads woven into one square inch
                    of fabric.
                  </h6>
                </div>
              </div>
              <div className="banner-img position-relative">
                <img
                  src={BannerImg4}
                  className="w-100 h-100 d-none d-md-flex"
                />
                <img
                  src={BannerPhoneImg4}
                  className="w-100 h-100 d-flex d-md-none"
                />
                <div className="overlayHomeBanner d-none d-md-flex"></div>
                <div className="banner-content position-absolute">
                  <h1 className="fw-bold">New Arrivals</h1>
                  <h6 className="banner-h6-content">
                    A higher GSM indicates a higher density of fibers in the
                    fabric, resulting in a softer and more durable sheet.
                    Generally, GSM values for bed sheets range from 75 to 120
                    and can reach up to 170. Thread count, on the other hand,
                    refers to the number of threads woven into one square inch
                    of fabric.
                  </h6>
                </div>
              </div>
              <div className="banner-img position-relative">
                <img
                  src={BannerImg5}
                  className="w-100 h-100 d-none d-md-flex"
                />
                <img
                  src={BannerPhoneImg5}
                  className="w-100 h-100 d-flex d-md-none"
                />
                <div className="overlayHomeBanner d-none d-md-flex"></div>
                <div className="banner-content position-absolute">
                  <h1 className="fw-bold">New Arrivals</h1>
                  <h6 className="banner-h6-content">
                    A higher GSM indicates a higher density of fibers in the
                    fabric, resulting in a softer and more durable sheet.
                    Generally, GSM values for bed sheets range from 75 to 120
                    and can reach up to 170. Thread count, on the other hand,
                    refers to the number of threads woven into one square inch
                    of fabric.
                  </h6>
                </div>
              </div>
              <div className="banner-img position-relative">
                <img
                  src={BannerImg6}
                  className="w-100 h-100 d-none d-md-flex"
                />
                <img
                  src={BannerPhoneImg3}
                  className="w-100 h-100 d-flex d-md-none"
                />
                <div className="overlayHomeBanner d-none d-md-flex"></div>
                <div className="banner-content position-absolute">
                  <h1 className="fw-bold">New Arrivals</h1>
                  <h6 className="banner-h6-content">
                    A higher GSM indicates a higher density of fibers in the
                    fabric, resulting in a softer and more durable sheet.
                    Generally, GSM values for bed sheets range from 75 to 120
                    and can reach up to 170. Thread count, on the other hand,
                    refers to the number of threads woven into one square inch
                    of fabric.
                  </h6>
                </div>
              </div>
              <div className="banner-img position-relative">
                <img
                  src={BannerImg7}
                  className="w-100 h-100 d-none d-md-flex"
                />
                <img
                  src={BannerPhoneImg2}
                  className="w-100 h-100 d-flex d-md-none"
                />
                <div className="overlayHomeBanner d-none d-md-flex"></div>
                <div className="banner-content position-absolute">
                  <h1 className="fw-bold">New Arrivals</h1>
                  <h6 className="banner-h6-content">
                    A higher GSM indicates a higher density of fibers in the
                    fabric, resulting in a softer and more durable sheet.
                    Generally, GSM values for bed sheets range from 75 to 120
                    and can reach up to 170. Thread count, on the other hand,
                    refers to the number of threads woven into one square inch
                    of fabric.
                  </h6>
                </div>
              </div>
              {/* {bannerData.map((data) => {
                return (
                  <div className="banner-img">
                    {data.FLD_Banner_URL.split(/[#?]/)[0]
                      .split(".")
                      .pop()
                      .trim() !== "mp4" ? (
                      <>
                        <>
                          <img
                            className="d-block w-100 h-100"
                            src={data.FLD_Banner_URL}
                            alt="slide"
                          />
                        </>
                      </>
                    ) : (
                      ""
                    )}
                    {data.FLD_Banner_URL.split(/[#?]/)[0]
                      .split(".")
                      .pop()
                      .trim() === "mp4" ? (
                      <>
                        <video
                          src={data.FLD_Banner_URL}
                          className="img-fluid w-100"
                          autoPlay
                          muted
                          loop
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })} */}
            </Slider>
          </>
        )}
      </div>
    </section>
  );
}

export default MainSlider;
