import React, { useEffect, useState } from "react";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import { Col, Container, Row } from "react-bootstrap";
import GetApiCall from "../Api/getApi";
import createDOMPurify from "dompurify";
import { Skeleton } from "antd";

function PrivacyPolicy() {
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  const DOMPurify = createDOMPurify(window);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetApiCall.getRequest("GetPrivacyPolicy").then((result) => {
      result.json().then((obj) => {
        setPrivacyPolicy(obj.data);
      });
    });
  }, []);
  return (
    <>
      <HeroBanner />
      <div className="container-fluid container-md">
        {privacyPolicy.length <= 0 ? (
          <Row className="justify-content-center">
            <Col lg={10}>
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="small"
              />
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="small"
              />
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="small"
              />
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="small"
              />

              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="small"
              />
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="small"
              />
              <Skeleton.Input
                active={true}
                className="mb-3"
                block
                size="small"
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="justify-content-center">
              <Col lg={10} className="pb-5">
                {privacyPolicy.map((data) => {
                  return (
                    <div
                      className="text-start section-description"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data.fld_privacypolicy),
                      }}></div>
                  );
                })}
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default PrivacyPolicy;
