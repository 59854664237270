import { createContext, useState } from "react";
export const store = createContext();
const Provider = ({ children }) => {
  const [activeLink, setactiveLink] = useState("Home");
  const [cartdrwaer, setcartdrwaer] = useState(false);
  const [modal, setModal] = useState(false);
  const [count, setCount] = useState([]);
  const [crousal, setcrousal] = useState(true);
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegistration, setopenRegistration] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [updateFeatureData, setupdateFeatureData] = useState(false);
  const [updateNewArrivals, setUpdateNewArrivals] = useState(false);
  const [updateBestSeller, setUpdateBestSeller] = useState(false);
  const [Cart, setCart] = useState(0);
  const { openSearch, setOpenSearch } = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [reloadCategory, setReloadCategory] = useState("");
  const [reloadSubCategory, setReloadSubCategory] = useState("");
  const [forgot, setForgot] = useState(false);
  const [CartPrice, setCartPrice] = useState([]);
  const [productCartLength, setproductCartLength] = useState([]);
  const [AddressCard, setAddressCard] = useState(false);
  const [updateCategoryData, setUpdateCategoryData] = useState(false);
  const [showLoginPopupWebsite, setLoginPopupWebsite] = useState(false);
  const [showLoginUserOption, setLoginUserOption] = useState(false);
  const [loginData, setloginData] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [ProductCart, setProductCart] = useState([]);
  const [addressBilling, setAddressBilling] = useState(null);
  const [addressShipping, setAddressShipping] = useState(null);
  const [changeAddress, setChangeAddress] = useState(false);
  const [showAddressSelectCheckout, setShowAddressSelectCheckout] = useState(false);
  const [showAddNewAddress, setShowAddNewAddress] = useState(null);
  const [changeAddressType, setChangeAddressType] = useState(null);
  const [cartItemBeforeLogin, setCartItemBeforeLogin] = useState(null);
  const [cartamount, setcartamount] = useState(null);
  const [featuredProducts, setFeaturedProducts] = useState([])
  const [newArrivals, setNewArrivals] = useState([])
  const [bestSeller, setBestSeller] = useState([])
  const [selectedBillingAddress, setSelectedBillingAddress] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [billingAddress, setBillingAddress] = useState(null);
  const [customerStateidForStock, setCustomerStateidForStock] = useState(null);

  return (
    <store.Provider
      value={{
        activeLink,
        setactiveLink,
        modal,
        setModal,
        count,
        setCount,
        crousal,
        setcrousal,
        open,
        setOpen,
        openLogin,
        setOpenLogin,
        openRegistration,
        setopenRegistration,
        updateData,
        setUpdateData,
        Cart,
        setCart,
        updateNewArrivals,
        setUpdateNewArrivals,
        updateFeatureData,
        setupdateFeatureData,
        updateBestSeller,
        setUpdateBestSeller,
        updateCategoryData,
        setUpdateCategoryData,
        openSearch,
        setOpenSearch,
        categoryList,
        setCategoryList,
        reloadCategory,
        setReloadCategory,
        reloadSubCategory,
        setReloadSubCategory,
        setForgot,
        forgot,
        cartdrwaer,
        setcartdrwaer,
        CartPrice,
        setCartPrice,
        productCartLength,
        setproductCartLength,
        AddressCard,
        setAddressCard,
        showLoginPopupWebsite,
        setLoginPopupWebsite,
        showLoginUserOption,
        setLoginUserOption,
        featuredProducts, setFeaturedProducts,
        newArrivals, setNewArrivals,
        bestSeller, setBestSeller,
        selectedBillingAddress, setSelectedBillingAddress,
        loginData, setloginData, showCart, setShowCart, ProductCart, setProductCart, addressBilling, setAddressBilling,
        addressShipping, setAddressShipping, changeAddress, setChangeAddress, changeAddressType, setChangeAddressType,
        showAddressSelectCheckout, setShowAddressSelectCheckout, showAddNewAddress, setShowAddNewAddress, cartItemBeforeLogin, setCartItemBeforeLogin, cartamount, setcartamount,
        selectedShippingAddress, setSelectedShippingAddress,
        setShippingAddress,
        shippingAddress,
        billingAddress,
        setBillingAddress,
        customerStateidForStock,
        setCustomerStateidForStock
      }}
    >
      {children}
    </store.Provider>
  );
};
export default Provider;
