var urlString = "https://api.bechofy.in/melhua-api/";
// var urlString = "http://localhost:8045/demostore-api/";
// var urlString = "http://localhost:8090/melhua-api/";

//var urlString = "https://api.bechofy.in/hairthentic-api/";

const GetApiCall = {
  getRequest(url) {
    return fetch(urlString + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        "x-auth-token":
          localStorage.getItem("access") != "undefined"
            ? JSON.parse(localStorage.getItem("access"))
            : null,
      },
    }).then((response) => {
      if (response.status == 401) {
        localStorage.removeItem("access");
        window.location.reload();
      } else {
        return response;
      }
    });
  },
};

export default GetApiCall;
