import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import PostApiCall from "../../Api/postApi";
import { store } from "../../context/store";
import { FaRegHeart } from "react-icons/fa";
import Login from "../Login/Login";
import "./AddToCart1.css";
import { FaCartShopping } from "react-icons/fa6";

function AddToCard1(props) {
  const product = props.product;
  const itemid = props.itemid;
  const { showLoginPopupWebsite, setLoginPopupWebsite } = useContext(store);
  const { ProductCart, setProductCart } = useContext(store);
  const { setCartItemBeforeLogin } = useContext(store);
  const { Cart, setCart } = useContext(store);
  const { loginData, setloginData } = useContext(store);
  const { featuredProducts, setFeaturedProducts } = useContext(store);
  const { newArrivals, setNewArrivals } = useContext(store);
  const { bestSeller, setBestSeller } = useContext(store);
  const GetCartdetails = () => {
    Notiflix.Loading.dots("");
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    var orderid = 0;
    if (localStorage.getItem("OrderDetails") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    if (localStorage.getItem("CustomerData") === null) {
      setLoginPopupWebsite(true);
    } else {
      var login = localStorage.getItem("CustomerData");
      var logindetails = JSON.parse(login);
      if (localStorage.getItem("OrderDetails") !== null) {
        var Order = localStorage.getItem("OrderDetails");
        var Orderdetails = JSON.parse(Order);
        PostApiCall.postRequest(
          {
            orderid:
              localStorage.getItem("OrderDetails") == null
                ? null
                : Orderdetails[0].order_id,
            id:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
          },
          "GetCartWebsite"
        )
          .then((res) => res.json())
          .then((res1) => {
            if (res1.data) {
              setProductCart(res1.data);
              setCart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
              Notiflix.Loading.remove();
            }
          });
      }
    }
    // PostApiCall.postRequest(
    //   {
    //     customerid:
    //       localStorage.getItem("CustomerData") == null
    //         ? null
    //         : logindetails[0].fld_customerid,
    //     categoryid: null,
    //     productid: null,
    //     variantid: null,
    //     sectionname: "Featured Products",
    //     orderid: orderid,
    //     itemchoice: "Random",
    //   },
    //   "GetWebsiteHomePageItems"
    // ).then((results) => {
    //   results.json()?.then((obj) => {
    //     if (results.status == 200 || results.status == 201) {
    //       // ;
    //       setFeaturedProducts(obj.data);
    //     }
    //   });
    // });
    // PostApiCall.postRequest(
    //   {
    //     customerid:
    //       localStorage.getItem("CustomerData") == null
    //         ? null
    //         : logindetails[0].fld_customerid,
    //     categoryid: null,
    //     productid: null,
    //     variantid: null,
    //     sectionname: "New Arrivals",
    //     orderid: orderid,
    //     itemchoice: "Random",
    //   },
    //   "GetWebsiteHomePageItems"
    // ).then((results) => {
    //   results.json()?.then((obj) => {
    //     if (results.status == 200 || results.status == 201) {
    //       // ;
    //       setNewArrivals(obj.data);
    //     }
    //   });
    // });
    // PostApiCall.postRequest(
    //   {
    //     customerid:
    //       localStorage.getItem("CustomerData") == null
    //         ? null
    //         : logindetails[0].fld_customerid,
    //     categoryid: null,
    //     productid: null,
    //     variantid: null,
    //     sectionname: "Best Seller",
    //     orderid: orderid,
    //     itemchoice: "Random",
    //   },
    //   "GetWebsiteHomePageItems"
    // ).then((results) => {
    //   results.json()?.then((obj) => {
    //     if (results.status == 200 || results.status == 201) {
    //       // ;
    //       setBestSeller(obj.data);
    //     }
    //   });
    // });
  };
  const addtocart = (props) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    if (logindetails != null) {
      setloginData(logindetails);
    }
    if (logindetails === null) {
      setLoginPopupWebsite(true);
      setCartItemBeforeLogin(itemid);
    } else {
      Notiflix.Loading.dots("");
      // ===========with Login=========
      PostApiCall.postRequest(
        {
          orderdate: moment().format("lll"),
          itemdetails: `
                     [
                     {
                         "Item":${itemid},
                         "Quantity":${1}
                     }
                     ]
                     `,
          status: "INCART",
          customerid:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_customerid,
          createdon: moment().format("lll"),
          updatedon: moment().format("lll"),
          orderid:
            localStorage.getItem("OrderDetails") == null
              ? 0
              : Orderdetails[0].order_id,
          updatedby:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_userid,
        },
        "AddToCartWebsite"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            Notiflix.Notify.success("Product added to Cart.");
            Notiflix.Loading.remove();
            if (localStorage.getItem("OrderDetails") == null) {
              {
                // ;
              }
              localStorage.setItem("OrderDetails", JSON.stringify(obj.data));
              GetCartdetails();
            } else {
              GetCartdetails();
            }
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(obj.data);
          }
        })
      );
    }
  };

  return (
    <>
      <button
        className="w-100 py-2 shop-btn d-flex align-content-center justify-content-center"
        onClick={addtocart}
      >
        {/* <FaCartShopping className="me-2 fs-sm-4 fs-5" /> */}
        Add to Cart
      </button>
      <Login />
    </>
  );
}
export default AddToCard1;
