import React, { useContext, useEffect, useState } from "react";
import GenerateAccess from "../../../Api/GenerateAccess";
import GetApiCall from "../../../Api/getApi";
import PostApiCall from "../../../Api/postApi";
import Login from "../../Login/Login";
import Banner from "../Banner/Banner";
import HomeProduct from "../HomeProduct/HomeProduct";
import Testimonial from "../Testimonial/Testimonial";
import ShopByCategory from "../ShopByCategory/ShopByCategory";
import { store } from "../../../context/store";
import HomePageProductSection from "../../HomePageProductSection/HomePageProductSection";

function Homepage() {
  const { updateFeatureData, setupdateFeatureData } = useContext(store);
  const { updateNewArrivals, setUpdateNewArrivals } = useContext(store);
  const { updatenew, setUpdatenewArrivals } = useContext(store);
  const { updateBestSeller, setUpdateBestSeller } = useContext(store);
  const { updateCategoryData, setUpdateCategoryData } = useContext(store);
  const { featuredProducts, setFeaturedProducts } = useContext(store);
  const { newArrivals, setNewArrivals } = useContext(store);
  const { bestSeller, setBestSeller } = useContext(store);
  const [categoryData, setCategoryData] = useState([]);
  const [homeCollection, setHomeCollections] = useState([]);

  useEffect(() => {
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    var orderid = 0;
    if (localStorage.getItem("OrderDetails") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    GetApiCall.getRequest("GetHomepageSetup").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        if (
          homepagesetupresult.status === 200 ||
          homepagesetupresult.status === 201
        ) {
          localStorage.setItem("homepagesetupresult", JSON.stringify(obj.data));
          obj.data
            .filter((value) => value.fld_section_name == "Featured Products")
            .map((filtereddata) => {
              PostApiCall.postRequest(
                {
                  customerid:
                    localStorage.getItem("CustomerData") == null
                      ? null
                      : logindetails[0].fld_customerid,
                  categoryid: null,
                  productid: null,
                  variantid: null,
                  sectionname: "Featured Products",
                  orderid: orderid,
                  itemchoice: filtereddata.fld_item_choice,
                  // pincode: 110004,
                },
                "GetWebsiteHomePageItems"
              ).then((results) => {
                results.json()?.then((obj) => {
                  if (results.status == 200 || results.status == 201) {
                    // ;
                    setFeaturedProducts(obj.data);
                  }
                });
              });
            });

          obj.data
            .filter((value) => value.fld_section_name == "New Arrivals")
            .map((filtereddata) => {
              PostApiCall.postRequest(
                {
                  customerid:
                    localStorage.getItem("CustomerData") == null
                      ? null
                      : logindetails[0].fld_customerid,
                  categoryid: null,
                  productid: null,
                  variantid: null,
                  sectionname: "New Arrivals",
                  orderid: orderid,
                  itemchoice: filtereddata.fld_item_choice,
                  // pincode: 110004,
                },
                "GetWebsiteHomePageItems"
              ).then((results) => {
                results.json()?.then((obj) => {
                  if (results.status == 200 || results.status == 201) {
                    // ;
                    setNewArrivals(obj.data);
                  }
                });
              });
            });

          obj.data
            .filter((value) => value.fld_section_name == "Best Seller")
            .map((filtereddata) => {
              PostApiCall.postRequest(
                {
                  customerid:
                    localStorage.getItem("CustomerData") == null
                      ? null
                      : logindetails[0].fld_customerid,
                  categoryid: null,
                  productid: null,
                  variantid: null,
                  sectionname: "Best Seller",
                  orderid: orderid,
                  itemchoice: filtereddata.fld_item_choice,
                  // pincode: 110004
                },
                "GetWebsiteHomePageItems"
              ).then((results) => {
                results.json()?.then((obj) => {
                  if (results.status == 200 || results.status == 201) {
                    setBestSeller(obj.data);
                  }
                });
              });
            });
        }
      });
    });
  }, []);

  useEffect(() => {
    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductVertical"
    ).then((results) => {
      results.json()?.then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setCategoryData(obj.data);
        }
      });
    });
  }, [updateCategoryData]);

  useEffect(() => {
    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetHomeCollection"
    ).then((results) => {
      results.json()?.then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setHomeCollections(obj.data);
        }
      });
    });
  }, []);

  return (
    <>
      <GenerateAccess />
      <Login />
      <Banner />

      {/* **** Category Page **** */}
      {localStorage.getItem("homepagesetupresult") != null
        ? JSON.parse(localStorage.getItem("homepagesetupresult"))
            .filter(
              (value) =>
                value.fld_section_name == "Shop By Category" &&
                value.fld_status == "Active"
            )
            .map((filtereddata) => {
              return (
                <ShopByCategory
                  productdata={categoryData}
                  header1={filtereddata.fld_headline1}
                  header2={filtereddata.fld_headline2}
                  header3={filtereddata.fld_headline3}
                  showprice={filtereddata.fld_show_price}
                  showshortdescription={filtereddata.fld_show_short_description}
                  showitemname={filtereddata.fld_show_item_name}
                  showaddrtocart={filtereddata.fld_show_add_to_cart}
                />
              );
            })
        : ""}

      {/* ****** Featured Products ****** */}
      {/* {localStorage.getItem("homepagesetupresult") != null ? JSON.parse(localStorage.getItem("homepagesetupresult")).filter(
        (value) => value.fld_section_name == "Featured Products" && value.fld_status == "Active"
      ).map(filtereddata => {
        return (
          <HomePageProductSection

            productdata={featuredProducts}
            header1={filtereddata.fld_headline1}
            header2={filtereddata.fld_headline2}
            header3={filtereddata.fld_headline3}
            showprice={filtereddata.fld_show_price}
            showshortdescription={filtereddata.fld_show_short_description}
            showitemname={filtereddata.fld_show_item_name}
            showaddrtocart={filtereddata.fld_show_add_to_cart}
          />
        )
      }) : ""
      } */}

      {/* ****** BEST Sellers ****** */}
      {/* {localStorage.getItem("homepagesetupresult") != null ? JSON.parse(localStorage.getItem("homepagesetupresult")).filter(
        (value) => value.fld_section_name == "Our Collection" && value.fld_status == "Active"
      ).map(filtereddata => {
        return (
          <HomePageProductSection

            productdata={newArrivals}
            header1={filtereddata.fld_headline1}
            header2={filtereddata.fld_headline2}
            header3={filtereddata.fld_headline3}
            showprice={filtereddata.fld_show_price}
            showshortdescription={filtereddata.fld_show_short_description}
            showitemname={filtereddata.fld_show_item_name}
            showaddrtocart={filtereddata.fld_show_add_to_cart}
          />
        )
      }) : ""
      } */}
      
      <HomeProduct productdata={newArrivals} featuredata={featuredProducts} />
      <Testimonial />
    </>
  );
}

export default Homepage;