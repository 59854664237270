import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import PostApiCall from "../../Api/postApi";
import Payment_unsuccessfull from "../../assets/images/Payment_unsuccessfull.png"
import { store } from "../../context/store";
import { AES } from "crypto-js";
import { useNavigate, useParams } from "react-router-dom";
import { ToWords } from "to-words";

const PaymentFail = () => {
    let navigate = useNavigate();

    useEffect(() => {
        Notiflix.Notify.init({
            width: "300px",
            position: "center-bottom",
            distance: "10px",
            opacity: 1,
        });
    }, [])

    return (
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div className="mt-3 text-end">
                            <button
                                // className="btn btn-theme change-addressButton mx-2"
                                className="btn shop-btn change-addressButton mx-2"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                Continue Shopping
                            </button>
                        </div>
                        <div className="d-flex justify-content-center">
                            <img src={Payment_unsuccessfull} alt="payment success" className="payment-fail-image"></img>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}
export default PaymentFail;
