import { Select } from "antd";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { AiFillInstagram } from "react-icons/ai";
import { BiCartAlt, BiUser } from "react-icons/bi";
import { FaFacebookF, FaSearch } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import GetApiCall from "../../Api/getApi";
import PostApiCall from "../../Api/postApi";
import { store } from "../../context/store";
import Register from "../Register/Register";
import ShoppingCard1 from "../ShoppingCard/ShoppingCard1";
import "./Navbar.css";

import { MdEmail } from "react-icons/md";

function SiteNavbar() {
  let navigate = useNavigate();
  const {
    showLoginPopupWebsite,
    setLoginPopupWebsite,
    showLoginUserOption,
    setLoginUserOption,
    showCart,
    setShowCart,
    Cart,
    setCart,
    loginData,
    setloginData,
    ProductCart,
    setProductCart,
  } = useContext(store);

  const [scroll, setScroll] = useState(false);
  const [activeKey, setActiveKey] = useState("home");
  const [Loading, setLoading] = useState(true);
  const [decButton, setdecButton] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [OfferData, setOfferData] = useState([]);
  const [CouponCode, setCouponCode] = useState(null);

  const [CouponId, setCouponId] = useState(null);
  const [cartID, setcartID] = useState(null);
  const [logo, setLogo] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // Navbar List
  const [options, setOptions] = useState([]);
  const handleShowCart = () => setShowCart(true);

  const searchProductsByVariant = () => {
    PostApiCall.postRequest({}, "Get_All_Items")
      .then((results) => {
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setSearchData(obj.data);
          }
          setLoading(false);
        });
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        setLoading(false);
      });
  };

  const handleSearch = (value) => {
    const searchInput = value.toLowerCase();
    const searchItems = searchData.filter((item) =>
      item.fld_variantname1.toLowerCase().includes(searchInput)
    );
    if (searchInput !== "") {
      const items = searchItems.map((item) => ({
        value: item.fld_variantid,
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {item.fld_variantname1}
            {value && (
              <img
                src={item.VariantImage}
                alt={item.fld_variantname1}
                className="nav-serach-item-img"
              />
            )}
          </div>
        ),
      }));
      setOptions(items);
    } else {
      setOptions([]);
    }
  };
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }
    if (window.pageYOffset > 0) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 0);
      });
    }
    searchProductsByVariant();
  }, []);

  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    if (logindetails != null) {
      setloginData(logindetails);
    }

    PostApiCall.postRequest(
      {
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res?.json())
      .then((res1) => {
        if (res1.data) {
          setLoading(false);

          setCart(res1?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          if (res1.data.length != 0) {
            setProductCart(res1.data);
            setcartID(res1.data[0].fld_cart_id);
            setCouponId(res1.data[0].fld_offerid);
            setCouponCode(res1.data[0].fld_code);
          } else {
            setProductCart(res1.data);
          }
        }
      });
    GetApiCall.getRequest("GetClientInfo").then((ClientInfo) => {
      ClientInfo.json().then((obj) => {
        setLogo(obj.data[0].logo_img);
        localStorage.setItem("logo", obj.data[0].logo_img);
      });
    });
  }, []);

  return (
    <>
      <Navbar
        expand="lg"
        className="sticky-top shadow-sm bg-navbar py-0 my-0 phn-sm-header"
        variant="light"
      >
        <Container fluid className="px-lg-4">
          <Navbar.Toggle />
          <Navbar.Brand href="/" className="me-0 py-0 my-0">
            <img src={logo} alt="" className="nav-logo" />
          </Navbar.Brand>
          <Navbar.Offcanvas placement="start">
            <Offcanvas.Header className="ps-1 pb-0 mb-0" closeButton>
              <img src={logo} alt="" className="logo" />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end align-items-lg-center align-items-start flex-grow-1 navbar-menu-list">
                <a href="/" className="nav-link">
                  Home
                </a>
                <a href="/about" className="nav-link">
                  About
                </a>
                <a href={`/shop`} className="nav-link">
                  Shop
                </a>
                <a href="/contact-us" className="nav-link">
                  Contact Us
                </a>

                {/* ******* For tab and phone ****** */}

                <a
                  href="/frequently-asked-questions"
                  className="nav-link d-block d-lg-none"
                >
                  FAQ's
                </a>
                <a href="/return-policy" className="nav-link d-block d-lg-none">
                  Return Policy
                </a>
                <a
                  href="/privacy-policy"
                  className="nav-link d-block d-lg-none"
                >
                  Privacy Policy
                </a>
                <a
                  href="/terms-and-conditions"
                  className="nav-link d-block d-lg-none"
                >
                  Terms Of Use
                </a>

                {/* Search item */}
                <div className="nav-search-input position-relative nav-search-width d-none d-lg-flex">
                  <Select
                    showSearch
                    placeholder="Search Here..."
                    filterOption={false}
                    onSearch={handleSearch}
                    options={options}
                    value={searchValue}
                    className="w-100"
                    onChange={(value) => {
                      navigate(`/product-detail/${value}`);
                      setOptions();
                      setSearchValue("");
                    }}
                    onInput={(e) => setSearchValue(e.target.value)}
                  />
                  <div className="h-100 mt-1 position-absolute top-0 search-icon-nav">
                    <FaSearch className="fs-5" />
                  </div>
                </div>

                {/* Only phone view */}
                <div className="col-12 phone-view-email px-0 d-lg-none d-block mt-2">
                  <ul className="list-unstyled mt-2">
                    <li>
                      <a
                        className="text-decoration-none d-flex align-items-center"
                        href="tel:+91 98182 46677"
                      >
                        <FaPhone className="icon me-2" /> +91 98182 46677
                      </a>
                    </li>
                    <li>
                      <a
                        className="mt-2 text-decoration-none d-flex align-items-center"
                        href="mailto:sales@meluhahome.com"
                      >
                        <MdEmail className="icon me-2" /> sales@meluhahome.com
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 footer-social-icon px-0 d-lg-none d-block">
                  <h3 className="fw-bolder side-follow-us">Follow Us</h3>
                  <ul className="d-flex list-unstyled mt-2">
                    <li>
                      <a
                        href="https://www.facebook.com/Aamnliving?mibextid=ZbWKwL"
                        target="blank"
                      >
                        <FaFacebookF className="icon" />
                      </a>
                    </li>
                    <li className="ms-3">
                      <a
                        href="https://www.instagram.com/meluha_home/?igshid=NTdlMDg3MTY%3D"
                        target="blank"
                      >
                        <AiFillInstagram className="icon" />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* End only phone view */}

                <div className="d-lg-flex d-none justify-content-between align-items-center gap-0 nav-ecom">
                  <div className="position-relative">
                    <span
                      className="nav-user cursor-pointer"
                      onClick={() => {
                        localStorage.getItem("CustomerData") == null
                          ? setLoginPopupWebsite(true)
                          : setLoginUserOption(true);
                      }}
                      // onMouseLeave={() => setLoginUserOption(false)}
                    >
                      <BiUser />
                    </span>
                    <div
                      className="user-profile-options"
                      onMouseOver={() => setLoginUserOption(true)}
                      style={{
                        display: showLoginUserOption == true ? "block" : "none",
                      }}
                    >
                      <a href="/profile">My Account</a>
                      <a
                        href="/"
                        onClick={() => {
                          localStorage.removeItem("CustomerData");
                          localStorage.removeItem("OrderDetails");
                          localStorage.removeItem("customeradd");
                        }}
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                  <div>
                    <Link to="#" className="nav-cart" onClick={handleShowCart}>
                      <BiCartAlt />
                      <span className="cart-badge">{Cart}</span>
                    </Link>
                  </div>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>

          <div className="d-lg-none d-flex justify-content-lg-between justify-content-end align-items-center nav-ecom">
            <div className="position-relative">
              <span
                className="nav-user cursor-pointer"
                onClick={() => {
                  localStorage.getItem("CustomerData") == null
                    ? setLoginPopupWebsite(true)
                    : setLoginUserOption(true);
                }}
                onMouseLeave={() => setLoginUserOption(false)}
              >
                <BiUser />
              </span>
              <div
                className="user-profile-options"
                onMouseOver={() => setLoginUserOption(true)}
                style={{
                  display: showLoginUserOption == true ? "block" : "none",
                }}
              >
                <a href="/profile">My Account</a>
                <a
                  href="/"
                  onClick={() => {
                    localStorage.removeItem("CustomerData");
                    localStorage.removeItem("OrderDetails");
                    localStorage.removeItem("customeradd");
                  }}
                >
                  Logout
                </a>
              </div>
            </div>
            <div>
              <Link to="#" className="nav-cart" onClick={handleShowCart}>
                <BiCartAlt />
                <span className="cart-badge">{Cart}</span>
              </Link>
            </div>
          </div>
        </Container>
      </Navbar>
      <ShoppingCard1 />
      <Register />
    </>
  );
}

export default SiteNavbar;
