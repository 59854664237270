import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { Alert, Col, Image, Offcanvas, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { BiRupee } from "react-icons/bi";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import PostApiCall from "../../Api/postApi";
import EmptyCartImage from "../../assets/images/empty_cart.png";
import { store } from "../../context/store";
import "./ShoppingCard1.css";

function ShoppingCard1(props) {
  let navigate = useNavigate();
  const { showCart, setShowCart } = useContext(store);
  const { ProductCart, setProductCart } = useContext(store);
  const { cartdrwaer, setcartdrwaer } = useContext(store);
  const { Cart, setCart } = useContext(store);
  const [decButton, setdecButton] = useState(false);
  const { setCartCount } = useContext(store);
  const { cartQuantity, setcartQuantity } = useContext(store);
  const { CartPrice, setCartPrice } = useContext(store);
  const { productCartLength, setproductCartLength } = useContext(store);
  const getCartdetails = () => {
    Notiflix.Loading.dots("");
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderDetails") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          setProductCart(res1.data);
          setCart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          Notiflix.Loading.remove();
        }
      });
  };
  function getExtension(filename) {
    return filename.split(".").pop();
  }
  const handleCloseCart = () => {
    setShowCart(false);
    // window.location.reload();
  };
  return (
    <Offcanvas show={showCart} onHide={handleCloseCart} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Shopping Cart</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pb-0">
        {ProductCart.length != 0 ? (
          <div class="cart-page-container">
            <Row>
              <Col lg={12} className="pb-3 border-bottom">
                <div class="row">
                  {ProductCart.map((data, index) => {
                    if (index == 0 && data.you_save_amount > 0) {
                      return (
                        <div className="col-lg-12">
                          <Alert variant="success" className="py-1 px-2 small">
                            Hurray! You saved <BiRupee />₹{" "}
                            {parseFloat(data.you_save_amount).toFixed(2)}
                          </Alert>
                        </div>
                      );
                    }
                  })}
                  {ProductCart.map((data, index) => {
                    return (
                      <>
                        <div className="col-lg-3 col-5">
                          <a href={`/product-detail/${data.fld_variantid}`}>
                            {getExtension(data.VariantImage) === "mp4" ? (
                              <video
                                src={data.VariantImage}
                                autoPlay
                                loop
                                autoFocus
                                autoCorrect="true"
                                muted
                                className="img-fluid w-100"
                              />
                            ) : (
                              <Image
                                src={data.VariantImage}
                                className="img-fluid w-100"
                              />
                            )}
                          </a>
                        </div>
                        <div className="col-lg-5 col-7">
                          <div class="mt-lg-0 mt-0">
                            <h6>
                              <a
                                href={`/product-detail/${data.fld_variantid}`}
                                onClick={() => setcartdrwaer(false)}
                                className="fw-bold cart-item-name"
                              >
                                {data.fld_variantname}
                              </a>
                            </h6>
                            <div class="d-flex">
                              <span className="mb-1">
                                {data.fld_ord_info !== "," ? (
                                  data.fld_ord_info.split(",").length <= 0 ? (
                                    <div className="variant-attributes">
                                      {data.fld_ord_info.split("#")[0]}-
                                      {data.fld_ord_info.split("#")[1]}
                                      <br />
                                    </div>
                                  ) : (
                                    data.fld_ord_info.split(",").map((info) => {
                                      return (
                                        <div className="variant-attributes">
                                          {info.split("#")[0]} -{" "}
                                          {info.split("#")[1]}
                                          <br />
                                        </div>
                                      );
                                    })
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                            <div class="_quantity-wrapper">
                              <div class="d-flex justify-content-between col-lg-4">
                                <h6 class="price price--end">
                                  <div className="_quantity-wrapper">
                                    <label
                                      className="visually-hidden"
                                      htmlFor="Quantity-1"
                                    >
                                      Quantity
                                    </label>
                                    <quantity-input
                                      class="quantity-checkout"
                                      type="text"
                                    >
                                      <button
                                        class="quantity__button no-js-hidden listing-page"
                                        name="minus"
                                        type="button"
                                        disabled={
                                          decButton == true ? true : false
                                        }
                                        onClick={() => {
                                          Notiflix.Loading.dots("");
                                          setdecButton(true);
                                          var login =
                                            localStorage.getItem(
                                              "CustomerData"
                                            );
                                          var logindetails = JSON.parse(login);
                                          var Order =
                                            localStorage.getItem(
                                              "OrderDetails"
                                            );
                                          var Orderdetails = JSON.parse(Order);
                                          if (
                                            data.fld_min_qty !=
                                              data.fld_quantity &&
                                            data.fld_quantity > 1
                                          ) {
                                            PostApiCall.postRequest(
                                              {
                                                orderdate:
                                                  moment().format("lll"),
                                                itemdetails: `
                                            [
                                              {
                                                "Item":${data.fld_variantid},
                                                "Quantity":${-1}
                                              }
                                            ]`,
                                                status: "INCART",
                                                customerid:
                                                  localStorage.getItem(
                                                    "CustomerData"
                                                  ) == null
                                                    ? null
                                                    : logindetails[0]
                                                        .fld_customerid,
                                                createdon:
                                                  moment().format("lll"),
                                                updatedon:
                                                  moment().format("lll"),
                                                orderid:
                                                  localStorage.getItem(
                                                    "OrderDetails"
                                                  ) == null
                                                    ? 0
                                                    : Orderdetails[0].order_id,
                                                updatedby:
                                                  localStorage.getItem(
                                                    "CustomerData"
                                                  ) == null
                                                    ? null
                                                    : logindetails[0]
                                                        .fld_userid,
                                              },
                                              "AddToCartWebsite"
                                            ).then((result) =>
                                              result.json().then((obj3) => {
                                                // ;
                                                if (
                                                  result.status == 200 ||
                                                  result.status == 201
                                                ) {
                                                  getCartdetails();
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.success(
                                                    "Product quantity updated."
                                                  );

                                                  setdecButton(false);
                                                  getCartdetails();
                                                  if (
                                                    localStorage.getItem(
                                                      "OrderDetails"
                                                    ) == null
                                                  ) {
                                                    //
                                                    localStorage.setItem(
                                                      "OrderDetails",
                                                      JSON.stringify(obj3.data)
                                                    );
                                                  }
                                                  getCartdetails();
                                                } else {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.failure(
                                                    obj3.data
                                                  );
                                                }
                                              })
                                            );
                                          }
                                          // {  }
                                          if (
                                            data.fld_quantity ==
                                              data.fld_min_qty ||
                                            data.fld_quantity == 1
                                          ) {
                                            Notiflix.Loading.dots("");
                                            PostApiCall.postRequest(
                                              {
                                                cartID: data.fld_cart_id,
                                                customerid:
                                                  localStorage.getItem(
                                                    "CustomerData"
                                                  ) == null
                                                    ? null
                                                    : logindetails[0]
                                                        .fld_customerid,
                                                cartlineid:
                                                  data.fld_cart_dtl_id,
                                                stateid: 0,
                                              },
                                              "DeleteCart"
                                            ).then((result) =>
                                              result.json().then((obj3) => {
                                                if (
                                                  result.status == 200 ||
                                                  result.status == 201
                                                ) {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.success(
                                                    "Product is successfully deleted."
                                                  );
                                                  setdecButton(false);
                                                  getCartdetails();
                                                  if (ProductCart.length == 1) {
                                                    localStorage.removeItem(
                                                      "OrderDetails"
                                                    );
                                                  }
                                                  getCartdetails();
                                                } else {
                                                  Notiflix.Notify.failure(
                                                    obj3.data
                                                  );
                                                }
                                              })
                                            );
                                          }
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="presentation"
                                          class="icon icon-minus"
                                          fill="none"
                                          viewBox="0 0 10 2"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </button>
                                      <input
                                        className="quantity__input"
                                        type="text"
                                        value={data.fld_quantity}
                                        min="0"
                                        id="Quantity-1"
                                        data-index="1"
                                      />
                                      <button
                                        className="quantity__button no-js-hidden listing-page"
                                        name="plus"
                                        type="button"
                                        onClick={() => {
                                          Notiflix.Loading.dots("");
                                          if (
                                            data.fld_quantity ==
                                            data.fld_max_qty
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "The item has reached its maximum number."
                                            );
                                          } else {
                                            var login =
                                              localStorage.getItem(
                                                "CustomerData"
                                              );
                                            var logindetails =
                                              JSON.parse(login);
                                            var Order =
                                              localStorage.getItem(
                                                "OrderDetails"
                                              );
                                            var Orderdetails =
                                              JSON.parse(Order);
                                            PostApiCall.postRequest(
                                              {
                                                orderdate:
                                                  moment().format("lll"),
                                                itemdetails: `
                                         [
                                           {
                                             "Item":${data.fld_variantid},
                                             "Quantity":${+1}
                                           }
                                         ]
                                         `,
                                                status: "INCART",
                                                customerid:
                                                  localStorage.getItem(
                                                    "CustomerData"
                                                  ) == null
                                                    ? null
                                                    : logindetails[0]
                                                        .fld_customerid,
                                                createdon:
                                                  moment().format("lll"),
                                                updatedon:
                                                  moment().format("lll"),
                                                orderid:
                                                  localStorage.getItem(
                                                    "OrderDetails"
                                                  ) == null
                                                    ? 0
                                                    : Orderdetails[0].order_id,
                                                updatedby:
                                                  localStorage.getItem(
                                                    "CustomerData"
                                                  ) == null
                                                    ? null
                                                    : logindetails[0]
                                                        .fld_userid,
                                              },
                                              "AddToCartWebsite"
                                            ).then((result) =>
                                              result.json().then((obj3) => {
                                                if (
                                                  result.status == 200 ||
                                                  result.status == 201
                                                ) {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.success(
                                                    "Product quantity updated."
                                                  );
                                                  // getCartdetails();
                                                  if (
                                                    localStorage.getItem(
                                                      "OrderDetails"
                                                    ) == null
                                                  ) {
                                                    //
                                                    localStorage.setItem(
                                                      "OrderDetails",
                                                      JSON.stringify(obj3.data)
                                                    );
                                                  }
                                                  getCartdetails();
                                                } else {
                                                  Notiflix.Notify.failure(
                                                    obj3.data
                                                  );
                                                }
                                              })
                                            );
                                          }
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="presentation"
                                          class="icon icon-plus"
                                          fill="none"
                                          viewBox="0 0 10 10"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </button>
                                    </quantity-input>
                                    <div className=" checkout-delete ms-3">
                                      <cart-remove-button
                                        id="Remove-1"
                                        data-index="1"
                                        className="Remove-1"
                                      >
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            Notiflix.Loading.dots("");
                                            var login =
                                              localStorage.getItem(
                                                "CustomerData"
                                              );
                                            var logindetails =
                                              JSON.parse(login);
                                            PostApiCall.postRequest(
                                              {
                                                cartID: data.fld_cart_id,
                                                customerid:
                                                  localStorage.getItem(
                                                    "CustomerData"
                                                  ) == null
                                                    ? null
                                                    : logindetails[0]
                                                        .fld_customerid,
                                                cartlineid:
                                                  data.fld_cart_dtl_id,
                                                stateid: 0,
                                              },
                                              "DeleteCart"
                                            ).then((result) =>
                                              result.json().then((obj3) => {
                                                if (
                                                  result.status == 200 ||
                                                  result.status == 201
                                                ) {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.success(
                                                    "Product is successfully deleted."
                                                  );
                                                  getCartdetails();
                                                  if (ProductCart.length == 1) {
                                                    localStorage.removeItem(
                                                      "OrderDetails"
                                                    );
                                                  }
                                                  getCartdetails();
                                                } else {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.failure(
                                                    obj3.data
                                                  );
                                                }
                                              })
                                            );
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            aria-hidden="true"
                                            focusable="false"
                                            role="presentation"
                                            className="icon icon-remove"
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                            }}
                                          >
                                            <path
                                              d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z"
                                              fill="currentColor"
                                            ></path>
                                            <path
                                              d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"
                                              fill="currentColor"
                                            ></path>
                                          </svg>
                                        </a>
                                      </cart-remove-button>
                                    </div>
                                  </div>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <h6 className="price price--end mb-0">
                            ₹ {parseFloat(data.Basevalue).toFixed(2)}
                          </h6>
                        </div>
                      </>
                    );
                  })}
                </div>
              </Col>
              <Col className="sticky-bottom">
                {ProductCart.map((data, index) => {
                  if (index == 0) {
                    return (
                      <div
                        class="cart-page-container cart-footer"
                        id="main-cart-footer"
                      >
                        <div class="cart-footer">
                          <div class="cart-blocks">
                            <div class="js-contents">
                              <div class="totals">
                                <h3 class="totals-subtotal">Amount Payable</h3>
                                <p class="totals-subtotal-value">
                                  {" "}
                                  ₹{" "}
                                  {parseFloat(
                                    data.fld_cart_amount_no_shipping
                                  ).toFixed(2)}
                                </p>
                              </div>
                              <small class="tax-note caption-large rte">
                                Final amount incl. tax &amp; Shipping at
                                checkout!
                              </small>
                            </div>
                            <div class="cart-ctas">
                              <div>
                                <a class="btn shop-btn" href="/">
                                  Continue Shopping
                                </a>
                              </div>
                              <div>
                                <a class="btn shop-btn" href="/checkout">
                                  Checkout
                                </a>
                              </div>
                            </div>
                            <div id="cart-errors"></div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </Col>
            </Row>
          </div>
        ) : (
          <>
            <div className="row cart-page-container">
              <div className="col-12 ml-5 mt-5 text-center">
                <img
                  src={EmptyCartImage}
                  className="empty-cart-image img-fluid w-100"
                  alt=""
                ></img>
                <h5
                  className="fw-bold"
                  style={{
                    fontSize: isMobile && "20px",
                  }}
                >
                  Your bag is empty.
                </h5>
                <h6
                  style={{
                    fontSize: isMobile && "16px",
                  }}
                >
                  Add Some Stuff!
                </h6>
                <button
                  // className="btn btn-theme change-addressButton mt-5"
                  className="btn shop-btn change-addressButton mt-5"
                  onClick={() => {
                    if (ProductCart.length == 0) {
                      localStorage.removeItem("OrderDetails");
                      // window.location.href = "/";
                      navigate("/");
                      setcartdrwaer(false);
                    } else {
                      // window.location.href = "/";
                      navigate("/");
                      setcartdrwaer(false);
                    }
                  }}
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
export default ShoppingCard1;
