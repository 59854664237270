import PostApiCall from "./postApiToken";
function GenerateAccess() {
    if (localStorage.getItem("access") == null || localStorage.getItem("access") == '' || localStorage.getItem("access") == 'null' || localStorage.getItem("access") == 'undefined') {

        PostApiCall.postRequest(
            {
                action: "Login",
                actiondate: null,
                email: process.env.REACT_APP_EMAIL,
                password: process.env.REACT_APP_PASSWORD
            },
            "AuthenticateUser"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    localStorage.setItem("access", JSON.stringify(obj.token));
                    window.location.reload()
                }
            }
            ))
    }


}
export default GenerateAccess